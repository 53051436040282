import { connect } from 'react-redux';
import Home from './home';

export default connect(
  ({ appState }) => ({
    chains: appState.chains,
  }),
  dispatch => ({

  })
)(Home);
