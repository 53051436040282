import { actions, localStorageKeys } from '../constants';
import ServerConfig from '../types/server-config';
import { Map, Set } from 'immutable';
import SystemInfo from '../types/system-info';
// import { ServerConfig } from '../types/server-config';

const getInitialState = () => ({
  locale: 'en',
  localeData: {},
  authKey: '',
  config: new ServerConfig(),
  domainVerified: null,
  lastDomainCheckTime: null,
  lastPortCheckTime: null,
  lastTLSCheckTime: null,
  platform: '',
  portVerified: null,
  tlsVerified: null,
  publicIP: '',
  ready: false,
  chains: [],
  nodeOutput: Map(),
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  chainsMeta: Map(),
  systemInfo: new SystemInfo(),
  nodePilotVersion: '',
  pocketSimulatedRelays: Set(), // Only applies to mainnet nodes
  nodeUpgrades: {},
  isRootUser: false,
  sessionStatuses: Map(),
});

export default (state = getInitialState(), { type, payload }) => {
  switch(type) {
    case actions.SET_WINDOW_SIZE:
      return {
        ...state,
        windowWidth: payload.width,
        windowHeight: payload.height
      };
    case actions.SET_READY:
      return {
        ...state,
        ready: payload.ready
      };
    case actions.SET_AUTH_KEY:
      localStorage.setItem(localStorageKeys.AUTH_KEY, payload.authKey);
      return {
        ...state,
        authKey: payload.authKey
      };
    case actions.SET_PUBLIC_IP:
      return {
        ...state,
        publicIP: payload.publicIP
      };
    case actions.SET_CONFIG:
      return {
        ...state,
        config: payload.config
      };
    case actions.SET_DOMAIN_VERIFIED:
      return {
        ...state,
        domainVerified: payload.verified
      };
    case actions.SET_PORT_VERIFIED:
      return {
        ...state,
        portVerified: payload.verified
      };
    case actions.SET_PLATFORM:
      return {
        ...state,
        platform: payload.platform
      };
    case actions.SET_LAST_DOMAIN_CHECK_TIME:
      return {
        ...state,
        lastDomainCheckTime: payload.lastDomainCheckTime
      };
    case actions.SET_LAST_PORT_CHECK_TIME:
      return {
        ...state,
        lastPortCheckTime: payload.lastPortCheckTime
      };
    case actions.SET_TLS_VERIFIED:
      return {
        ...state,
        tlsVerified: payload.verified
      };
    case actions.SET_LAST_TLS_CHECK_TIME:
      return {
        ...state,
        lastTLSCheckTime: payload.lastTLSCheckTime
      };
    case actions.SET_CHAINS:
      return {
        ...state,
        chains: payload.chains
      };
    case actions.SET_NODE_OUTPUT: {
      const [ _id, output ] = payload.nodeOutput;
      const { nodeOutput } = state;
      const prevOutput = nodeOutput.get(_id) || [];
      const newOutput = nodeOutput.set(_id, [
        ...prevOutput,
        output
      ].slice(-200));
      return {
        ...state,
        nodeOutput: newOutput
      };
    } case actions.SET_CHAIN_META: {
      const { _id, data } = payload;
      return {
        ...state,
        chainsMeta: state.chainsMeta.set(_id, data)
      };
    } case actions.SET_POCKET_SIMULATED_RELAYS: {
      return {
        ...state,
        pocketSimulatedRelays: Set(payload.simulatedRelays),
      };
    } case actions.SET_SYSTEM_INFO: {
      const { systemInfo } = payload;
      return {
        ...state,
        systemInfo
      };
    } case actions.SET_NODE_PILOT_VERSION: {
      return {
        ...state,
        nodePilotVersion: payload.version
      };
    } case actions.SET_NODE_UPGRADES: {
      return {
        ...state,
        nodeUpgrades: payload.nodeUpgrades
      };
    } case actions.SET_IS_ROOT_USER: {
      return {
        ...state,
        isRootUser: payload.isRootUser
      };
    } case actions.SET_SESSION_STATUSES: {
      return {
        ...state,
        sessionStatuses: payload.sessionStatuses
          .reduce((map, status) => map.set(status.id, status), Map())
      };
    } default:
      return state;
  }
};
