import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getChainName,
  getCoinImage,
  titleCase,
  getChainRoleName,
  handleError,
  generatePassword,
  timeout, clientIdToName
} from '../../util';
import {
  chainDefaults,
  chains,
  networkTypes,
  nodeTypes,
  routes,
  socketEndpoints,
  Role,
  clientsByChain
} from '../../constants';
import $ from 'jquery';
import SystemInfo from '../../types/system-info';
import ss from 'socket.io-stream';
import swal from 'sweetalert';

let modalNode;

const ChainModal = ({ authKey, history, socket, systemInfo }) => {

  const [ selectedChain, setSelectedChain ] = useState(chains.ETH);
  const chainDefault = chainDefaults[selectedChain];
  const [ selectedCores, setSelectedCores ] = useState(chainDefault.cpus);
  const [ selectedMem, setSelectedMem ] = useState(chainDefault.mem / 1024);
  const [ selectedNetwork, setSelectedNetwork ] = useState(chainDefault.networks[0]);
  const [ selectedChainType, setSelectedChainType ] = useState(chainDefault.roles[0]);
  const [ disableSubmit, setDisableSubmit ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);
  const [ password, setPassword ] = useState('');
  const [ passwordRepeat, setPasswordRepeat ] = useState('');
  const [ importPercent, setImportPercent ] = useState(0);
  const [ rawPrivateKey, setRawPrivateKey ] = useState('');
  const [ showPrivateKey, setShowPrivateKey ] = useState(false);
  const [ client, setClient ] = useState('');

  useEffect(() => {
    setSelectedNetwork(chainDefaults[selectedChain].networks[0]);
    if(selectedChain === chains.POKT) {
      setSelectedChainType(Role.VALIDATOR);
    } else {
      setSelectedChainType(Role.NODE);
    }
    setPassword('');
    setPasswordRepeat('');
    setShowPassword(false);
    setRawPrivateKey('');
    setShowPrivateKey(false);
    const availableClients = clientsByChain[selectedChain];
    setClient(availableClients && availableClients.length > 0 ? availableClients[0] : '')
  }, [selectedChain]);

  const onSubmit = e => {
    e.preventDefault();
    setDisableSubmit(true);
    const data = {
      type: nodeTypes.MANAGED,
      role: selectedChainType,
      ticker: selectedChain,
      network: selectedNetwork,
      rpcPort: 0,
      cpus: selectedCores,
      mem: selectedMem * 1024,
      rawPrivateKey: selectedChainType === Role.VALIDATOR ? rawPrivateKey : '',
      client,
    };
    if(password && password !== passwordRepeat) {
      setDisableSubmit(false);
      return handleError(new Error('Password and repeated password do not match.'));
    }
    socket.emit(socketEndpoints.ADD_CHAIN, authKey, data, password, '', (err, id) => {
      if(err) {
        handleError(err);
        setDisableSubmit(false);
      } else {
        $('#js-newChainModal').modal('hide');
        history.push(routes.CHAIN + '/' + id);
      }
    });
  };

  const chainName = getChainName(selectedChain);

  const onChainClick = (e, chain) => {
    e.preventDefault();
    setSelectedChain(chain);
    const cpus = chainDefaults[chain].cpus;
    setSelectedCores(cpus);
    const mem = chainDefaults[chain].mem / 1024;
    setSelectedMem(mem);
  };

  const onClientClick = (e, c) => {
    e.preventDefault();
    setClient(c);
  };

  const onNetworkClick = (e, network) => {
    e.preventDefault();
    setSelectedNetwork(network);
  };

  const onNodeTypeClick = (e, nodeType) => {
    e.preventDefault();
    setSelectedChainType(nodeType);
  };

  const onCoreNumClick = (e, num) => {
    e.preventDefault();
    setSelectedCores(num);
  };

  const onMemNumClick = (e, num) => {
    e.preventDefault();
    setSelectedMem(num);
  };

  const toggleShowHidePassword = e => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const onGeneratePasswordClick = async function(e) {
    e.preventDefault();
    try {
      const newPassword = await generatePassword(socket, authKey);
      setPassword(newPassword);
      setPasswordRepeat(newPassword);
    } catch(err) {
      handleError(err);
    }
  };

  const onPasswordChange = e => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const onPasswordRepeatChange = e => {
    e.preventDefault();
    setPasswordRepeat(e.target.value);
  };

  const toggleShowHidePrivateKey = e => {
    e.preventDefault();
    setShowPrivateKey(!showPrivateKey);
  };

  const onPrivateKeyChange = e => {
    e.preventDefault();
    setRawPrivateKey(e.target.value);
  };

  const onImportClick = e => {
    e.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/zip';
    input.onchange = () => {
      setDisableSubmit(true);
      let [ file ] =   Array.from(input.files);
      const name = file.name
        .replace(/\s+/g, '_');
      const { size } = file;
      const stream = ss.createStream();
      ss(socket).emit(socketEndpoints.UPLOAD_NODE_FILE, stream, {
        authKey,
        name,
        size: file.size,
      }, async (err, nodeData) => {
        if(err) {
          handleError(err);
          setDisableSubmit(false);
          setImportPercent(0);
        } else {
          try {
            const ticker = nodeData.ticker.toUpperCase();
            const text = nodeData.address ?
              `You are about to import ${ticker} node with address:\n\n${nodeData.address}\n\nContinue?`
              :
              `You are about to import an ${ticker} node. Continue?`;
            const confirmed = await swal({
              title: 'Confirm Import',
              text,
              buttons: [
                {text: 'Cancel', visible: true, closeModal: true},
                {text: `Import ${ticker} node`, visible: true, closeModal: false},
              ],
              closeOnEsc: false,
              closeOnClickOutside: false,
            });
            if(confirmed) {
              let password = '';
              if(nodeData.role === Role.VALIDATOR) {
                const div = document.createElement('div');
                div.className = 'swal-content';
                const input = document.createElement('input');
                const note = document.createElement('p');
                note.appendChild(document.createTextNode('Enter the master password used when the node was exported. This will decrypt the node\'s password and then add it to your encrypted password store using your current master password.'));
                div.appendChild(note);
                input.placeholder = 'Enter decryption password';
                input.type = 'password';
                input.className = 'swal-content__input';
                div.appendChild(input);
                password = await swal({
                  title: 'Decrypt Node Password',
                  content: {
                    element: 'input',
                    attributes: {
                      placeholder: 'Enter previous master password',
                      title: 'Enter the master password used in the Node Pilot instance where the node was exported from.',
                      type: 'password',
                    },
                  },
                  buttons: [
                    {text: 'Cancel', visible: true, closeModal: true},
                    {text: 'Import', visible: true, closeModal: false},
                  ],
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                });
                if(!password) {
                  setDisableSubmit(false);
                  setImportPercent(0);
                  return;
                }
              }
              $('#js-newChainModal').modal('hide');
              const id = await new Promise((resolve, reject) => {
                socket.emit(socketEndpoints.IMPORT_NODE_FILE, authKey, nodeData.dir, password, (err, res) => {
                  if (err) {
                    reject(err);
                  } else {
                    resolve(res);
                  }
                });
              });
              // await timeout(100);
              swal.close();
              await timeout(500);
              history.push(routes.CHAIN + '/' + id);
            }
          } catch(err) {
            handleError(err);
          }
          setDisableSubmit(false);
          setImportPercent(0);
        }
      });
      const blobStream = ss.createBlobReadStream(file);
      let uploaded = 0;
      blobStream.on('data', function(chunk) {
        uploaded += chunk.length;
        setImportPercent(Math.floor(uploaded / size * 100));
      });
      blobStream.pipe(stream);
    };
    input.click();
  };

  const coreOptions = [];
  for(let i = 1; i < systemInfo.cpuTotal + 1; i++) {
    coreOptions.push(i);
  }

  return (
    <div ref={node => modalNode = node} className={'modal fade'} id={'js-newChainModal'}>
      <div className={'modal-dialog modal-dialog-centered'}>
        <form className={'modal-content'} onSubmit={onSubmit}>
          <div className="modal-header">
            <h4 className="modal-title">Add Chain</h4>
            <button type="button" className="close" data-dismiss="modal"><span>&times;</span></button>
          </div>
          <div className={'modal-body'}>

            <div className={'form-group d-flex flex-row justify-content-center'}>
              <img style={{height: 50}} src={getCoinImage(selectedChain)} alt={`${selectedChain} icon`}/>
            </div>

            <div className={'form-group'}>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle btn-lg w-100"
                        type="button" id="dropdownMenuButton"
                        data-toggle="dropdown">{ chainName }</button>
                <div className="dropdown-menu w-100">
                  {Object.values(chains)
                    .map(chain => {
                      return (
                        <a key={chain} className="dropdown-item" href="#" onClick={e => onChainClick(e, chain)}><img style={{display: 'inline-block', height: 16}} className={'mr-2'} src={getCoinImage(chain)} alt={`${chain} icon`} /> {getChainName(chain)}</a>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className={'form-group'}>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle btn-lg w-100"
                        type="button" id="dropdownMenuButton"
                        data-toggle="dropdown">{clientIdToName(client)} client</button>
                <div className="dropdown-menu w-100">
                  {(clientsByChain[selectedChain] || [])
                    .map(c => {
                      return (
                        <a key={`${selectedChain}-${c}`} className="dropdown-item" href="#" onClick={e => onClientClick(e, c)}>{clientIdToName(c)}</a>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className={'form-group'}>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle btn-lg w-100"
                        type="button" id="dropdownMenuButton"
                        data-toggle="dropdown">{titleCase(selectedNetwork)}</button>
                <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                  {chainDefaults[selectedChain].networks
                    .filter(n => n !== networkTypes.RINKEBY)
                    .map(network => {
                      return (
                        <a key={network} className="dropdown-item" href="#" onClick={e => onNetworkClick(e, network)}>{titleCase(network)}</a>
                      );
                    })
                  }
                </div>
              </div>
            </div>

            <div className={'form-group'}>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle btn-lg w-100"
                        type="button" id="dropdownMenuButton"
                        data-toggle="dropdown">{getChainRoleName(selectedChainType)}</button>
                <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                  {chainDefaults[selectedChain].roles
                    .map(nodeType => {
                      return (
                        <a key={nodeType} className="dropdown-item" href="#" onClick={e => onNodeTypeClick(e, nodeType)}>{getChainRoleName(nodeType)}</a>
                      );
                    })
                  }
                </div>
              </div>
            </div>

            <div className={'form-group row'}>
              <div className={'col'}>
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle btn-lg w-100"
                          type="button" id="dropdownMenuButton"
                          data-toggle="dropdown">{`${selectedCores} ${selectedCores === 1 ? 'vCPU' : 'vCPUS'}`}</button>
                  <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                    {coreOptions
                      .map(num => {
                        return (
                          <a key={`${num}-core`} className="dropdown-item" href="#" onClick={e => onCoreNumClick(e, num)}>{num}</a>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
              <div className={'col'}>
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle btn-lg w-100"
                          type="button" id="dropdownMenuButton"
                          data-toggle="dropdown">{`${selectedMem} GiB`}</button>
                  <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 24, 32]
                      .map(num => {
                        return (
                          <a key={`${num}-mem`} className="dropdown-item" href="#" onClick={e => onMemNumClick(e, num)}>{num}</a>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </div>

            {selectedChainType === Role.VALIDATOR ?
              <div>
                <div className={'form-group'}>
                  <label className={'d-flex flex-row justify-content-start'}>Key Password: (<a href={'#'} onClick={toggleShowHidePassword}>{showPassword ? 'Hide' : 'Show'}</a>) <em>Minimum of 12 characters</em><span className={'flex-grow-1'} /><a href={'#'} onClick={onGeneratePasswordClick}>Generate Password</a></label>
                  <input type={showPassword ? 'text' : 'password'} className={'form-control form-control-lg'} placeholder={'Enter key password'} required={true} pattern={'.{12,}'} value={password} onChange={onPasswordChange} />
                </div>
                <div className={'form-group'}>
                  <input type={showPassword ? 'text' : 'password'} className={'form-control form-control-lg'} placeholder={'Repeat key password'} required={true} pattern={'.{12,}'} value={passwordRepeat} onChange={onPasswordRepeatChange} />
                </div>
                <div className={'form-group'}>
                  <label className={'d-flex flex-row justify-content-start'}>Raw Private Key (optional): (<a href={'#'} onClick={toggleShowHidePrivateKey}>{showPrivateKey ? 'Hide' : 'Show'}</a>)<span className={'flex-grow-1'} /></label>
                  <input title={'If entered, this will generate the account using the raw private key specified. Otherwise, a private key will be automatically generated.'} type={showPrivateKey ? 'text' : 'password'} className={'form-control form-control-lg'} placeholder={'Enter optional raw private key'} value={rawPrivateKey} onChange={onPrivateKeyChange} />
                </div>
              </div>
              :
              null
            }

          </div>
          <div className={'modal-footer'}>
            {disableSubmit ?
              <button disabled={true} className={'btn btn-info'}>Advanced</button>
              :
              <Link to={routes.ADD_NEW_CHAIN + `?ticker=${selectedChain}`} disabled={disableSubmit} className={'btn btn-info'} onClick={() => $('#js-newChainModal').modal('hide')}>Advanced</Link>
            }
            <button disabled={disableSubmit} className={'btn btn-info'} onClick={onImportClick}>{`Import${importPercent ? ' ' + importPercent + '%' : ''}`}</button>
            <div style={{flexGrow: 1}} />
            <button className={'btn btn-secondary'} disabled={disableSubmit} data-dismiss={'modal'}>Cancel</button>
            <button type={'submit'} className={'btn btn-primary'} disabled={disableSubmit}>Create Node</button>
          </div>
        </form>
      </div>
    </div>
  );
};
ChainModal.propTypes = {
  authKey: PropTypes.string,
  history: PropTypes.object,
  socket: PropTypes.object,
  systemInfo: PropTypes.instanceOf(SystemInfo),
};

export default connect(
  ({ appState }) => ({
    authKey: appState.authKey,
    systemInfo: appState.systemInfo,
  })
)(ChainModal);
