import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { socketEndpoints } from '../../constants';
import { handleError } from '../../util';
import nodePilotWhite from '../../images/node_pilot_white.png';
import daLogoWhite from '../../images/da_logo_white.png';

const Login = ({ socket, setAuthKey }) => {

  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const styles = {
    container: {
      position: 'absolute',
      left: 10,
      right: 10,
      top: 0,
      bottom: 0
    },
    innerContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 110,
      overflow: 'auto',
    },
    formContainer: {
      width: 500,
      maxWidth: '100%'
    },
    logoImage: {
      width: 300,
      maxWidth: '90%',
      marginBottom: 16,
    },
    daLogoContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 20
    },
    daLogoImage: {
      width: 200,
      maxWidth: '90%',
    },
  };

  const onSubmit = async function(e) {
    e.preventDefault();
    const preppedEmail = email.trim();
    if(!preppedEmail) {
      await swal('Oops!', 'You must enter a valid email address.', 'error');
    } else if(!password) {
      await swal('Oops!', 'You must enter a password.', 'error');
    } else { // name, email, password, and password repeat are good
      socket.emit(socketEndpoints.AUTHENTICATE, { email, password }, (err, authKey) => {
        if(err) {
          handleError(err);
          setAuthKey('');
        } else {
          setAuthKey(authKey);
        }
      });
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer} className={'d-flex flex-row justify-content-center align-items-center'}>
        <div style={styles.formContainer}>
          <div className={'d-flex flex-row justify-content-center'}>
            <img style={styles.logoImage} src={nodePilotWhite} alt={'Node Pilot Logo'} />
          </div>
          <form onSubmit={onSubmit}>
            <div className={'form-group'}>
              <label>EMAIL:</label>
              <input className={'form-control'} type={'email'} value={email} onChange={e => setEmail(e.target.value.toLowerCase())} autoFocus required />
            </div>
            <div className={'form-group'}>
              <label>PASSWORD:</label>
              <input className={'form-control'} type={'password'} value={password} onChange={e => setPassword(e.target.value)} required />
            </div>
            <div className={'form-group'}>
              <button type={'submit'} className={'btn btn-outline-primary btn-lg w-100'}>LOGIN</button>
            </div>
          </form>
        </div>
      </div>

      <div style={styles.daLogoContainer} className={'d-flex flex-column justify-content-center align-items-center'}>
        <div>BUILT BY:</div>
        <img src={daLogoWhite} style={styles.daLogoImage} alt={'Decentralized Authority Logo'} />
      </div>

    </div>
  );
};
Login.propTypes = {
  socket: PropTypes.object,
  setAuthKey: PropTypes.func
};

export default withRouter(Login);
