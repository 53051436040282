module.exports.actions = {
  SET_READY: 'SET_READY',
  SET_WINDOW_SIZE: 'SET_WINDOW_SIZE',
  SET_AUTH_KEY: 'SET_AUTH_KEY',
  SET_PUBLIC_IP: 'SET_PUBLIC_IP',
  SET_CONFIG: 'SET_CONFIG',
  SET_DOMAIN_VERIFIED: 'SET_DOMAIN_VERIFIED',
  SET_PORT_VERIFIED: 'SET_PORT_VERIFIED',
  SET_TLS_VERIFIED: 'SET_TLS_VERIFIED',
  SET_PLATFORM: 'SET_PLATFORM',
  SET_LAST_DOMAIN_CHECK_TIME: 'SET_LAST_DOMAIN_CHECK_TIME',
  SET_LAST_PORT_CHECK_TIME: 'SET_LAST_PORT_CHECK_TIME',
  SET_LAST_TLS_CHECK_TIME: 'SET_LAST_TLS_CHECK_TIME',
  SET_CHAINS: 'SET_CHAINS',
  SET_NODE_OUTPUT: 'SET_NODE_OUTPUT',
  SET_CHAIN_META: 'SET_CHAIN_META',
  SET_SYSTEM_INFO: 'SET_SYSTEM_INFO',
  SET_NODE_PILOT_VERSION: 'SET_NODE_PILOT_VERSION',
  SET_POCKET_SIMULATED_RELAYS: 'SET_POCKET_SIMULATED_RELAYS',
  SET_NODE_UPGRADES: 'SET_NODE_UPGRADES',
  SET_IS_ROOT_USER: 'SET_IS_ROOT_USER',
  SET_SESSION_STATUSES: 'SET_SESSION_STATUSES',
};

module.exports.platforms = {
  WIN: 'win32',
  DARWIN: 'darwin',
  LINUX: 'linux'
}

module.exports.routes = {
  HOME: '/',
  REGISTER: '/register',
  LOGIN: '/login',
  DNS_CONFIGURATION: '/dns-configuration',
  PORT_CONFIGURATION: '/port-configuration',
  TLS_CONFIGURATION: '/tls-configuration',
  CREATE_POCKET_NODE: '/create-pocket-node',
  ADD_NEW_CHAIN: '/add-new-chain',
  CHAIN: '/chain',
  NODE: '/node',
};

module.exports.socketEndpoints = {
  FIRST_RUN: 'FIRST_RUN',
  AUTHENTICATE: 'AUTHENTICATE',
  REGISTER: 'REGISTER',
  VALIDATE_AUTH_KEY: 'VALIDATE_AUTH_KEY',
  GET_PUBLIC_IP: 'GET_PUBLIC_IP',
  PUBLIC_IP: 'PUBLIC_IP',
  GET_SERVER_CONFIG: 'GET_SERVER_CONFIG',
  UPDATE_SERVER_CONFIG: 'UPDATE_SERVER_CONFIG',
  SERVER_CONFIG: 'SERVER_CONFIG',
  VERIFY_DOMAIN: 'VERIFY_DOMAIN',
  DOMAIN_VERIFICATION: 'DOMAIN_VERIFICATION',
  VERIFY_PORT: 'VERIFY_PORT',
  PORT_VERIFICATION: 'PORT_VERIFICATION',
  GET_PLATFORM: 'GET_PLATFORM',
  PLATFORM: 'PLATFORM',
  VERIFY_TLS: 'VERIFY_TLS',
  TLS_VERIFICATION: 'TLS_VERIFICATION',
  CONFIGURE_TLS: 'CONFIGURE_TLS',
  TLS_CERT_EXISTS: 'TLS_CERT_EXISTS',
  RENEW_TLS_CERT: 'RENEW_TLS_CERT',
  RESTART_SERVER: 'RESTART_SERVER',
  LETSENCRYPT_OUTPUT: 'LETSENCRYPT_OUTPUT',
  NODES: 'NODES',
  GET_CHAINS: 'GET_CHAINS',
  CHAINS: 'CHAINS',
  ADD_CHAIN: 'GET_CHAIN',
  GET_DEFAULT_PORT: 'GET_DEFAULT_PORT',
  NODE_OUTPUT: 'NODE_OUTPUT',
  GET_NEXT_CHAIN_PORT: 'GET_NEXT_CHAIN_PORT',
  GENERATE_PASSWORD: 'GENERATE_PASSWORD',
  GET_TOTAL_MEMORY: 'GET_TOTAL_MEMORY',
  LOG_OUT: 'LOG_OUT',
  GENERATE_BACKUP: 'GENERATE_BACKUP',
  GET_DEFAULT_CHAINS_DIR: 'GET_DEFAULT_CHAINS_DIR',
  READDIR: 'READDIR',
  GET_CHAIN_META: 'GET_CHAIN_META',
  CHAIN_META: 'GET_CHAIN_META',
  GET_SYSTEM_INFO: 'GET_SYSTEM_INFO',
  SYSTEM_INFO: 'SYSTEM_INFO',
  POCKET_COMMAND_SUBMIT: 'POCKET_COMMAND_SUBMIT',
  GET_LOG_KEY: 'GET_LOG_KEY',
  GENERATE_NODE_EXPORT: 'GENEREATE_NODE_EXPORT',
  UPLOAD_NODE_FILE: 'UPLOAD_NODE_FILE',
  IMPORT_NODE_FILE: 'IMPORT_NODE_FILE',
  RESTART_NODE: 'RESTART_NODE',
  GET_NODE_PILOT_VERSION: 'GET_NODE_PILOT_VERSION',
  SHUTDOWN: 'SHUTDOWN',
  FULL_SHUTDOWN: 'FULL_SHUTDOWN',
  TOGGLE_POCKET_SIMULATED_RELAY: 'TOGGLE_POCKET_SIMULATED_RELAY',
  GET_POCKET_SIMULATED_RELAYS: 'GET_POCKET_SIMULATED_RELAYS',
  POCKET_SIMULATED_RELAYS: 'POCKET_SIMULATED_RELAYS',
  GET_PRIVATE_KEY: 'GET_PRIVATE_KEY',
  GET_NODE_UPGRADES: 'GET_NODE_UPGRADES',
  NODE_UPGRADES: 'NODE_UPGRADES',
  UPGRADE_NODE: 'UPGRADE_NODE',
  UPDATE_REMOTE_NODE_VERSIONS_MANIFEST: 'UPDATE_REMOTE_NODE_VERSIONS_MANIFEST',
  PULL_SNAPSHOT_DATA: 'PULL_SNAPSHOT_DATA',
  GET_NODE_BALANCE: 'GET_NODE_BALANCE',
  GET_POCKET_CHAINS: 'GET_POCKET_CHAINS',
  REMOVE_RELAY_CHAIN: 'REMOVE_RELAY_CHAIN',
  ADD_RELAY_CHAIN: 'ADD_RELAY_CHAIN',
  STOP_NODE: 'STOP_NODE',
  CLEAR_NODE_DATA_DIRECTORY: 'CLEAR_NODE_DATA_DIRECTORY',
  CHAIN_USED_EXCLUSIVELY_IN_NODES: 'CHAIN_USED_EXCLUSIVELY_IN_NODES',
  DELETE_NODE: 'DELETE_NODE',
  IS_ROOT_USER: 'IS_ROOT_USER',
  GET_NODE_KEY: 'GET_NODE_KEY',
  GET_KEY_PASSWORD: 'GET_KEY_PASSWORD',
  GET_VALIDATOR_INFO: 'GET_VALIDATOR_INFO',
  STAKE_VALIDATOR: 'STAKE_VALIDATOR',
  UNJAIL_VALIDATOR: 'UNJAIL_VALIDATOR',
  RESTAKE_VALIDATOR: 'RESTAKE_VALIDATOR',
  GET_SESSION_STATUSES: 'GET_SESSION_STATUSES',
};

module.exports.localStorageKeys = {
  AUTH_KEY: 'AUTH_KEY'
};

module.exports.nodeTypes = {
  MANAGED: 'MANAGED',
  EXTERNAL: 'EXTERNAL',
};

module.exports.transportProtocols = {
  HTTP: 'http',
  HTTPS: 'https',
};

module.exports.POCKET_MAINNET_DEFAULT_SEEDS = '03b74fa3c68356bb40d58ecc10129479b159a145@seed1.mainnet.pokt.network:20656,64c91701ea98440bc3674fdb9a99311461cdfd6f@seed2.mainnet.pokt.network:21656,0057ee693f3ce332c4ffcb499ede024c586ae37b@seed3.mainnet.pokt.network:22856,9fd99b89947c6af57cd0269ad01ecb99960177cd@seed4.mainnet.pokt.network:23856,f2a4d0ec9d50ea61db18452d191687c899c3ca42@seed5.mainnet.pokt.network:24856,f2a9705924e8d0e11fed60484da2c3d22f7daba8@seed6.mainnet.pokt.network:25856,582177fd65dd03806eeaa2e21c9049e653672c7e@seed7.mainnet.pokt.network:26856,2ea0b13ab823986cfb44292add51ce8677b899ad@seed8.mainnet.pokt.network:27856,a5f4a4cd88db9fd5def1574a0bffef3c6f354a76@seed9.mainnet.pokt.network:28856,d4039bd71d48def9f9f61f670c098b8956e52a08@seed10.mainnet.pokt.network:29856,18eaabef85c661344b640b74597c4973af707ccb@pocket-seed.simply-vc.com.mt:26656,5c133f07ed296bb9e21e3e42d5f26e0f7d2b2832@poktseed100.chainflow.io:26656,361b1936d3fbe516628ebd6a503920fc4fc0f6a7@seed.pokt.rivet.cloud:26656';
module.exports.POCKET_TESTNET_DEFAULT_SEEDS = '3487f08b9e915f347eb4372b406326ffbf13d82c@testnet-seed-1.nodes.pokt.network:4301,27f4295d1407d9512a25d7f2ea91d1a415660c16@testnet-seed-2.nodes.pokt.network:4302,0beb1a93fe9ce2a3b058b98614f1ed0f5ad664d5@testnet-seed-3.nodes.pokt.network:4303,8fd656162dbbe0402f3cef111d3ad8d2723eef8e@testnet-seed-4.nodes.pokt.network:4304,80100476b67fea2e94c6b2f72e40cf8f6062ed21@testnet-seed-5.nodes.pokt.network:4305,370edf0882e094e83d4087d5f8801bbf24f5d931@testnet-seed-6.nodes.pokt.network:4306,57aff5a049846d14e2dcc06fdcc241d7ebe6a3eb@testnet-seed-7.nodes.pokt.network:4307,545fb484643cf2efbcf01ee2b7bc793ef275cd84@testnet-seed-8.nodes.pokt.network:4308';

const chains = {
  POKT: 'pokt',
  ETH: 'eth',
  XDAI: 'xdai',
  AVAX: 'avax',
  FUSE: 'fuse',
  ONE: 'one',
  BSC: 'bsc',
  BTC: 'btc',
  // ICX: 'icx',
  BCH: 'bch',
  DASH: 'dash',
  // DGB: 'dgb',
  LBC: 'lbc',
  LTC: 'ltc',
  OEC: 'oec',
  IOTEX: 'iotex',
  MATIC: 'matic',
};
module.exports.chains = chains;

const clients = {
  CORE: 'CORE',
  GETH: 'GETH',
  OPEN_ETHEREUM: 'OPEN_ETHEREUM',
  NETHERMIND: 'NETHERMIND',
  ERIGON: 'ERIGON',
};
module.exports.clients = clients;

module.exports.chainStatus = {
  STOPPED: 'STOPPED',
  RUNNING: 'RUNNING',
  SYNCING: 'SYNCING',
  JAILED: 'JAILED',
  UNKNOWN: 'UNKNOWN',
}

const cpuDefault = 4;
const memDefault = 8192;

const networkTypes = {
  MAINNET: 'MAINNET',
  TESTNET: 'TESTNET',
  RINKEBY: 'RINKEBY',
  GOERLI: 'GOERLI',
};
module.exports.networkTypes = networkTypes;

const Role = {
  NODE: 'NODE',
  VALIDATOR: 'VALIDATOR',
};
module.exports.Role = Role;

module.exports.chainDefaults = {
  pokt: {
    port: 8080,
    cpus: 16,
    mem: 24576,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.VALIDATOR,
    ],
    divisor: 1000000,
    minStake: 15050000000,
  },
  eth: {
    port: 8545,
    cpus: 8,
    mem: 16384,
    networks: [
      networkTypes.MAINNET,
      networkTypes.GOERLI,
      networkTypes.RINKEBY,
    ],
    roles: [
      Role.NODE,
    ],
  },
  fuse: {
    port: 8545,
    cpus: 6,
    mem: 8192,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
      // Role.VALIDATOR,
    ],
    divisor: 1000000000000000000,
    minStake: 100000000000000000000000,
  },
  bsc: {
    port: 8545,
    cpus: 8,
    mem: 32768,
    networks: [
      networkTypes.MAINNET,
      // networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  avax: {
    port: 9650,
    cpus: 8,
    mem: 16384,
    networks: [
      networkTypes.MAINNET,
      // networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  btc: {
    port: 8332,
    cpus: cpuDefault,
    mem: memDefault,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  icx: {
    port: 9000,
    cpus: 2,
    mem: memDefault,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  bch: {
    port: 10332,
    cpus: cpuDefault,
    mem: memDefault,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  dash: {
    port: 9998,
    cpus: cpuDefault,
    mem: memDefault,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  dgb: {
    port: 14022,
    cpus: cpuDefault,
    mem: memDefault,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  lbry: {
    port: 9245,
    cpus: cpuDefault,
    mem: memDefault,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  lbc: {
    port: 9245,
    cpus: cpuDefault,
    mem: memDefault,
    networks: [
      networkTypes.MAINNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  ltc: {
    port: 9332,
    cpus: cpuDefault,
    mem: memDefault,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  one: {
    port: 9500,
    cpus: 8,
    mem: 32768,
    networks: [
      networkTypes.MAINNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  sys: {
    port: 8370,
    cpus: cpuDefault,
    mem: memDefault,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  xdai: {
    port: 8545,
    cpus: 6,
    mem: 8192,
    networks: [
      networkTypes.MAINNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  oec: {
    port: 8545,
    cpus: 4,
    mem: 8192,
    networks: [
      networkTypes.MAINNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  iotex: {
    port: 8545,
    cpus: 8,
    mem: 16384,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
  matic: {
    port: 8545,
    cpus: 6,
    mem: 16384,
    networks: [
      networkTypes.MAINNET,
      networkTypes.TESTNET,
    ],
    roles: [
      Role.NODE,
    ],
  },
};

module.exports.clientsByChain = {
  pokt: [
    clients.CORE,
  ],
  eth: [
    clients.GETH,
    clients.NETHERMIND,
    clients.ERIGON,
  ],
  xdai: [
    clients.NETHERMIND,
  ],
  avax: [
    clients.CORE,
  ],
  fuse: [
    clients.OPEN_ETHEREUM,
  ],
  one: [
    clients.CORE,
  ],
  bsc: [
    clients.GETH,
  ],
  btc: [
    clients.CORE,
  ],
  bch: [
    clients.CORE,
  ],
  dash: [
    clients.CORE,
  ],
  lbc: [
    clients.CORE,
  ],
  ltc: [
    clients.CORE,
  ],
  oec: [
    clients.CORE,
  ],
  iotex: [
    clients.CORE,
  ],
  matic: [
    clients.CORE,
  ],
};
