const { networkTypes: NetworkType, Role } = require('../constants');

class NLNode {

  id = '';
  ticker = '';
  name = '';
  version = '';
  clientVersion = '';
  archival = false;
  dockerImage = '';
  network = '';
  peerPort = '';
  rpcPort = '';
  rpcUsername = '';
  rpcPassword = '';
  client = '';
  dockerCPUs = 0;
  dockerMem = 0;
  dockerNetwork = '';
  dataDir = '';
  walletDir = '';
  configDir = '';
  createdAt = '';
  updatedAt = '';
  remote = false;
  remoteDomain = '';
  remoteProtocol = '';
  keyPass = '';
  role = Role.NODE;
  address = '';

  constructor(data) {
    Object.assign(this, data);
  }

  loadBalancerId() {
    const { ticker, network, archival } = this;
    switch(ticker) {
      case 'avax':
        if(network === NetworkType.MAINNET) {
          if(archival) {
            return 'avax-archival';
          } else {
            return 'avax-mainnet';
          }
        } else {
          return '';
        }
      case 'bsc':
        if(network === NetworkType.MAINNET) {
          if(archival) {
            return 'bsc-archival';
          } else {
            return 'bsc-mainnet';
          }
        } else if(network === NetworkType.TESTNET) {
          return 'bsc-testnet';
        } else {
          return '';
        }
      case 'btc':
        if(network === NetworkType.MAINNET) {
          return 'btc-mainnet';
        } else {
          return '';
        }
      case 'eth':
        if(network === NetworkType.MAINNET) {
          if(archival) {
            return 'eth-archival';
          } else {
            return 'eth-mainnet';
          }
        } else if(network === NetworkType.GOERLI) {
          return 'eth-goerli';
        } else {
          return '';
        }
      case 'fuse':
        if(network === NetworkType.MAINNET) {
          if(archival) {
            return 'fuse-archival';
          } else {
            return 'fuse-mainnet';
          }
        } else {
          return '';
        }
      case 'pokt':
        if(network === NetworkType.MAINNET) {
          return 'pocket-mainnet';
        } else {
          return '';
        }
      case 'xdai':
        if(network === NetworkType.MAINNET) {
          if(archival) {
            return 'poa-xdai-archival';
          } else {
            return 'poa-xdai';
          }
        } else {
          return '';
        }
      case 'one':
        if(network === NetworkType.MAINNET) {
          if(archival) {
            return 'harmony-0-archival';
          } else {
            return 'harmony-0';
          }
        } else {
          return '';
        }
      case 'matic':
        if(network === NetworkType.MAINNET) {
          if(archival) {
            return 'poly-archival';
          } else {
            return 'poly-mainnet';
          }
        } else if(network === NetworkType.TESTNET) {
          return 'poly-mumbai';
        } else {
          return '';
        }
      case 'iotex':
        if(network === NetworkType.MAINNET) {
          return 'iotex-mainnet';
        } else {
          return '';
        }
      case 'oec':
        if(network === NetworkType.MAINNET) {
          return 'oec-mainnet';
        } else {
          return '';
        }
      default:
        return '';
    }
  }

}

export default NLNode;
