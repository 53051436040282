import { connect } from 'react-redux';
import TLSConfiguration from './tls-configuration';

export default connect(
  ({ appState }) => ({
    config: appState.config,
    authKey: appState.authKey,
    tlsVerified: appState.tlsVerified,
    lastTLSCheckTime: appState.lastTLSCheckTime,
    portVerified: appState.portVerified,
    domainVerified: appState.domainVerified,
  })
)(TLSConfiguration);
