import 'jquery';
import 'bootstrap';
import React from 'react';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import './hyper.icons.css';
import './app.dark.css';
import './main.css';
import * as appActions from './actions/app-actions';
import appReducer from './reducers/app-reducer';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/app';

const combinedReducers = combineReducers({
  appState: appReducer
});

const store = createStore(combinedReducers);
console.log('state', store.getState());
store.subscribe(() => {
  const state = store.getState();
  // console.log('state', state);
});

window.addEventListener('resize', e => {
  const { innerWidth, innerHeight } = e.target;
  store.dispatch(appActions.setWindowSize(innerWidth, innerHeight));
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
