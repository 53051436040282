import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import { localStorageKeys, routes, socketEndpoints } from '../constants';
import io from 'socket.io-client';
import { handleError } from '../util';
import * as appActions from '../actions/app-actions';
import Register from './register';
import Home from './home';
import Login from './login';
import Navbar from './shared/navbar';
import DNSConfiguration from './dns-configuration';
import PortConfiguration from './port-configuration';
import ServerConfig from '../types/server-config';
import moment from 'moment';
import TLSConfiguration from './tls-configuration';
import AddNewChain from './add-new-chain';
import ChainView from './chain';
import ChainMeta from "../types/chain-meta";
import SystemInfo from '../types/system-info';
import NLNode from '../types/NLNode';
import { LocalizeContext } from '../hooks/localize-hook';
import { Localize } from '../modules/localize';

const App = ({ locale, localeData, ready, authKey, history, location, chains, width, height, isRootUser, setReady, setAuthKey, setPublicIP, setConfig, setDomainVerified, setLastDomainCheckTime, setPortVerified, setLastPortCheckTime, setPlatform, setTLSVerified, setLastTLSCheckTime, setChains, setNodeOutput, setChainMeta, setSystemInfo, setNodePilotVersion, setPocketSimulatedRelays, setNodeUpgrades, setIsRootUser, setSessionStatuses }) => {

  const [ localize, setLocalize ] = useState(new Localize(locale, localeData));

  useEffect(() => {
    setLocalize(new Localize(locale, localeData));
  }, [locale, localeData]);

  const { pathname } = location;

  const [ socket, setSocket ] = useState();

  useEffect(() => {

    const savedAuthKey = localStorage.getItem(localStorageKeys.AUTH_KEY);

    // const socket = io('localhost:34416');
    const socket = io(location.origin);

    setSocket(socket);
    socket.on('connect', () => {

      socket.emit(socketEndpoints.FIRST_RUN, (err, isFirstRun) => {
        if(err) {
          return handleError(err);
        } else if(isFirstRun) {
          history.push(routes.REGISTER);
        }
        if(!isFirstRun && savedAuthKey) {
          socket.emit(socketEndpoints.VALIDATE_AUTH_KEY, savedAuthKey, (err, valid) => {
            if(err) {
              handleError(err);
            } else {
              if(valid) setAuthKey(savedAuthKey);
              setReady(true);
            }
          });
        } else {
          setReady(true);
        }
      });

    });

    socket.on(socketEndpoints.PUBLIC_IP, ip => {
      setPublicIP(ip);
    });

    socket.on(socketEndpoints.SERVER_CONFIG, config => {
      if(config) setConfig(new ServerConfig(config));
    });

    socket.on(socketEndpoints.DOMAIN_VERIFICATION, verified => {
      setDomainVerified(verified);
      setLastDomainCheckTime(moment());
    });

    socket.on(socketEndpoints.PORT_VERIFICATION, verified => {
      setPortVerified(verified);
      setLastPortCheckTime(moment());
    });

    socket.on(socketEndpoints.TLS_VERIFICATION, verified => {
      setTLSVerified(verified);
      setLastTLSCheckTime(moment());
    });

    socket.on(socketEndpoints.PLATFORM, platform => {
      setPlatform(platform);
    });

    socket.on(socketEndpoints.CHAINS, chains => {
      setChains(chains.map(n => new NLNode(n)));
    });

    socket.on(socketEndpoints.NODE_OUTPUT, ([_id, output]) => {
      setNodeOutput([_id, output]);
    });

    socket.on(socketEndpoints.CHAIN_META, ([id, data]) => {
      setChainMeta(id, data);
    });

    socket.on(socketEndpoints.SYSTEM_INFO, info => {
      const systemInfo = new SystemInfo(info);
      setSystemInfo(systemInfo);
    });

    socket.on(socketEndpoints.POCKET_SIMULATED_RELAYS, simulatedRelays => {
      setPocketSimulatedRelays(simulatedRelays);
    });

    socket.on(socketEndpoints.NODE_UPGRADES, nodeUpgrades => {
      setNodeUpgrades(nodeUpgrades);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected!');
    });
  }, []);

  useEffect(() => {
    let verifyDomainInterval,
      verifyPortInterval,
      verifyTLSInterval,
      getSystemInfoInterval,
      getNodeUpgradesInterval,
      getSessionStatusesInterval;
    if(socket && ready && authKey) {
      socket.emit(socketEndpoints.GET_NODE_PILOT_VERSION, authKey, (err, version) => {
        setNodePilotVersion(version);
      });
      socket.emit(socketEndpoints.GET_PUBLIC_IP, authKey, err => {
        if(err) handleError(err);
      });
      socket.emit(socketEndpoints.GET_SERVER_CONFIG, authKey, err => {
        if(err) handleError(err);
      });
      socket.emit(socketEndpoints.VERIFY_DOMAIN, authKey, err => {
        if(err) handleError(err);
      });
      socket.emit(socketEndpoints.VERIFY_PORT, authKey, err => {
        if(err) handleError(err);
      });
      socket.emit(socketEndpoints.VERIFY_TLS, authKey, err => {
        if(err) handleError(err);
      });
      socket.emit(socketEndpoints.GET_PLATFORM, authKey, err => {
        if(err) handleError(err);
      });
      socket.emit(socketEndpoints.GET_CHAINS, authKey, err => {
        if(err) handleError(err);
      });
      socket.emit(socketEndpoints.GET_SYSTEM_INFO, authKey, err => {
        if(err) handleError(err);
      });
      socket.emit(socketEndpoints.GET_POCKET_SIMULATED_RELAYS, authKey, err => {
        if(err) handleError(err);
      });
      socket.emit(socketEndpoints.UPDATE_REMOTE_NODE_VERSIONS_MANIFEST, authKey, (err, success) => {
        if (err)
          handleError(err);
        socket.emit(socketEndpoints.GET_NODE_UPGRADES, authKey, (err1) => {
          if(err1) handleError(err1);
        });
      });
      verifyDomainInterval = setInterval(() => {
        socket.emit(socketEndpoints.VERIFY_DOMAIN, authKey, err => {
          if(err) handleError(err);
        });
      }, 30000);
      verifyPortInterval = setInterval(() => {
        socket.emit(socketEndpoints.VERIFY_PORT, authKey, err => {
          if(err) handleError(err);
        });
      }, 30000);
      verifyTLSInterval = setInterval(() => {
        socket.emit(socketEndpoints.VERIFY_TLS, authKey, err => {
          if(err) handleError(err);
        });
      }, 30000);
      getSystemInfoInterval = setInterval(() => {
        socket.emit(socketEndpoints.GET_SYSTEM_INFO, authKey, err => {
          if(err) handleError(err);
        });
      }, 5000);
      getNodeUpgradesInterval = setInterval(() => {
        socket.emit(socketEndpoints.GET_NODE_UPGRADES, authKey, err => {
          if(err) handleError(err);
        });
      }, 60000 * 30); // 30 minutes
      socket.emit(socketEndpoints.IS_ROOT_USER, authKey, (err, isRootUser) => {
        if(err) handleError(err);
        setIsRootUser(isRootUser);
      });
      const getSessionStatuses = () => {
        socket.emit(socketEndpoints.GET_SESSION_STATUSES, authKey, (err, statuses) => {
          if(err) handleError(err);
          else setSessionStatuses(statuses);
        });
      }
      getSessionStatusesInterval = setInterval(getSessionStatuses, 60000);
      getSessionStatuses();
    }
    return () => {
      clearInterval(verifyDomainInterval);
      clearInterval(verifyPortInterval);
      clearInterval(verifyTLSInterval);
      clearInterval(getSystemInfoInterval);
      clearInterval(getNodeUpgradesInterval);
      clearInterval(getSessionStatusesInterval);
    }
  }, [socket, ready, authKey]);

  useEffect(() => {
    let chainMetaInterval;
    if(socket && ready && authKey) {
      chainMetaInterval = setInterval(async function() {
        for(const chain of chains) {
          await new Promise(resolve => {
            socket.emit(socketEndpoints.GET_CHAIN_META, authKey, chain, err => {
              if(err) handleError(err);
              resolve();
            });
          })
        }
      }, 10000);
      (async function() {
        for(const chain of chains) {
          await new Promise(resolve => {
            socket.emit(socketEndpoints.GET_CHAIN_META, authKey, chain, err => {
              if(err) handleError(err);
              resolve();
            });
          })
        }
      })();
      return () => {
        clearInterval(chainMetaInterval);
      };
    }
  }, [chains, socket, ready, authKey]);

  if(!ready) return <div />;

  if(!authKey && pathname !== routes.REGISTER) return <Login socket={socket} />;

  return (
    <LocalizeContext.Provider value={localize}>
      <div style={{width, height}}>
        {pathname !== routes.REGISTER && pathname !== routes.LOGIN ? <Navbar socket={socket} /> : null}
        {pathname !== routes.REGISTER && pathname !== routes.LOGIN && isRootUser ? <h3 className={'text-center text-danger mb-0'}>*** YOU ARE CURRENTLY RUNNING NODE PILOT AS ROOT USER. ***</h3> : null}
        {pathname !== routes.REGISTER && pathname !== routes.LOGIN && isRootUser ? <h3 className={'text-center text-danger mb-0'}>*** NODE PILOT SHOULD ONLY BE RUN AS A REGULAR USER. ***</h3> : null}
        <Route path={routes.HOME} exact={true} component={props => <Home {...props} socket={socket} />} />
        <Route path={routes.REGISTER} component={props => <Register {...props} socket={socket} />} />
        <Route path={routes.LOGIN} component={props => <Login {...props} socket={socket} />} />
        <Route path={routes.DNS_CONFIGURATION} component={props => <DNSConfiguration {...props} socket={socket} />} />
        <Route path={routes.PORT_CONFIGURATION} component={props => <PortConfiguration {...props} socket={socket} />} />
        <Route path={routes.TLS_CONFIGURATION} component={props => <TLSConfiguration {...props} socket={socket} />} />
        <Route path={routes.ADD_NEW_CHAIN} component={props => <AddNewChain {...props} socket={socket} />} />
        <Route path={`${routes.CHAIN}/:id`} component={props => <ChainView {...props} socket={socket} />} />
      </div>
    </LocalizeContext.Provider>
  );
};
App.propTypes = {
  locale: PropTypes.string,
  localeData: PropTypes.object,
  ready: PropTypes.bool,
  authKey: PropTypes.string,
  history: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  isRootUser: PropTypes.bool,
  chains: PropTypes.arrayOf(PropTypes.instanceOf(NLNode)),
  setPublicIP: PropTypes.func,
  setConfig: PropTypes.func,
  setPlatform: PropTypes.func,
  setDomainVerified: PropTypes.func,
  setLastDomainCheckTime: PropTypes.func,
  setPortVerified: PropTypes.func,
  setLastPortCheckTime: PropTypes.func,
  setTLSVerified: PropTypes.func,
  setLastTLSCheckTime: PropTypes.func,
  setNodeOutput: PropTypes.func,
  setChainMeta: PropTypes.func,
  setNodePilotVersion: PropTypes.func,
  setPocketSimulatedRelay: PropTypes.func,
  setIsRootUser: PropTypes.func,
};

export default withRouter(connect(
  ({ appState }) => ({
    locale: appState.locale,
    localeData: appState.localeData,
    ready: appState.ready,
    authKey: appState.authKey,
    chains: appState.chains,
    width: appState.windowWidth,
    height: appState.windowHeight,
    isRootUser: appState.isRootUser,
  }),
  dispatch => ({
    setReady: ready => dispatch(appActions.setReady(ready)),
    setAuthKey: authKey => dispatch(appActions.setAuthKey(authKey)),
    setPublicIP: publicIP => dispatch(appActions.setPublicIP(publicIP)),
    setConfig: config => dispatch(appActions.setConfig(config)),
    setDomainVerified: verified => dispatch(appActions.setDomainVerified(verified)),
    setLastDomainCheckTime: lastDomainCheckTime => dispatch(appActions.setLastDomainCheckTime(lastDomainCheckTime)),
    setPortVerified: verified => dispatch(appActions.setPortVerified(verified)),
    setLastPortCheckTime: lastPortCheckTime => dispatch(appActions.setLastPortCheckTime(lastPortCheckTime)),
    setTLSVerified: verified => dispatch(appActions.setTLSVerified(verified)),
    setLastTLSCheckTime: lastTLSCheckTime => dispatch(appActions.setLastTLSCheckTime(lastTLSCheckTime)),
    setPlatform: platform => dispatch(appActions.setPlatform(platform)),
    setChains: chains => dispatch(appActions.setChains(chains)),
    setNodeOutput: nodeOutput => dispatch(appActions.setNodeOutput(nodeOutput)),
    setChainMeta: (_id, data) => dispatch(appActions.setChainMeta(_id, new ChainMeta(data))),
    setSystemInfo: systemInfo => dispatch(appActions.setSystemInfo(systemInfo)),
    setNodePilotVersion: version => dispatch(appActions.setNodePilotVersion(version)),
    setPocketSimulatedRelays: simulatedRelays => dispatch(appActions.setPocketSimulatedRelays(simulatedRelays)),
    setNodeUpgrades: nodeUpgrades => dispatch(appActions.setNodeUpgrades(nodeUpgrades)),
    setIsRootUser: isRootUser => dispatch(appActions.setIsRootUser(isRootUser)),
    setSessionStatuses: statuses => dispatch(appActions.setSessionStatuses(statuses)),
  })
)(App));
