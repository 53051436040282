class SystemInfo {

  /**
   * @type {string}
   */
  memUnits = '';

  /**
   * @type {number}
   */
  memTotal = 0;

  /**
   * @type {number}
   */
  memUsed = 0;

  /**
   * @type {number}
   */
  memPercent = 0;

  /**
   * @type {string}
   */
  diskUnits = '';

  /**
   * @type {number}
   */
  diskTotal = 0;

  /**
   * @type {number}
   */
  diskUsed = 0;

  /**
   * @type {number}
   */
  diskPercent = 0;

  /**
   * @type {number}
   */
  cpuTotal = 0;

  /**
   * @type {number}
   */
  cpuPercent = 0;

  constructor(data) {
    Object.assign(this, data);
  }

}

export default SystemInfo;
