export class PocketChainDataItem {

  id = '';
  url = '';

  constructor(data) {
    Object.assign(this, data);
  }

}
