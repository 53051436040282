import React from 'react';
import PropTypes from 'prop-types';
import AddNewChain from '../add-new-chain';
import NLNode from '../../types/NLNode';

const ChainView = props => {
  const { chains, history, match, socket } = props;
  const { id } = match.params;
  const chain = chains.find(c => c.id === id);

  if(!chain) {
    return (
      <div />
    );
  }

  return (
    <AddNewChain readOnly={true} history={history} socket={socket} selectedChain={chain} />
  );
};
ChainView.propTypes = {
  chains: PropTypes.arrayOf(PropTypes.instanceOf(NLNode)),
  history: PropTypes.object,
  socket: PropTypes.object,
};

export default ChainView;
