class ChainMeta {

  /**
   * @type {string}
   */
  blockHeight = '';

  /**
   * @type {string}
   */
  status = '';

  /**
   * @type {string}
   */
  version = '';

  /**
   * @type {string}
   */
  memUsed = '';

  /**
   * @type {string}
   */
  memAllocated = '';

  /**
   * @type {string} ISO Date String
   */
  startTime = '';

  /**
   * @param {{blockHeight: string, status: string, version: string}} data
   */
  constructor(data = {}) {
    this.blockHeight = data.blockHeight || '';
    this.status = data.status || '';
    this.version = data.version || '';
    this.memUsed = data.memUsed || '';
    this.memAllocated = data.memAllocated || '';
    this.startTime = data.startTime || '';
  }

  /**
   * @param {{blockHeight: string, status: string, version: string}} data
   */
  set(data) {
    return new ChainMeta({
      ...this,
      ...data
    });
  }

};

export default ChainMeta;
