import PropTypes from 'prop-types';
import React from 'react';

const Code = ({ children }) => {
  return <span className={'text-monospace'} style={{backgroundColor: 'rgba(255,255,255,.15)'}}>{children}</span>
};
Code.propTypes = {
  children: PropTypes.string
};

export default Code;
