import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { routes, socketEndpoints } from '../../constants';
import { handleError } from '../../util';
import { Link } from 'react-router-dom';
import ServerConfig from '../../types/server-config';
import swal from 'sweetalert';
import nodePilotWhite from '../../images/node_pilot_white.png';
import { LocalizeContext } from '../../hooks/localize-hook';

const Navbar = ({ authKey, nodePilotVersion, domainVerified, portVerified, tlsVerified, publicIP, config, socket }) => {

  const localize = useContext(LocalizeContext);

  const onLogOut = e => {
    e.preventDefault();
    socket.emit(socketEndpoints.LOG_OUT, authKey, err => {
      if(err) handleError(err);
      else window.location = '/';
    })
  };

  const onBackup = async function(e) {
    e.preventDefault();
    const confirmed = await swal({
      title: localize.text('Download Backup File?', 'navbar'),
      text: localize.text('Would you like to download a zipped backup file of your NP configuration?', 'navbar'),
      buttons: [
        localize.text('Cancel', 'universal'),
        localize.text('Ok', 'universal'),
      ],
    });
    if(!confirmed) return;
    socket.emit(socketEndpoints.GENERATE_BACKUP, authKey, (err, filePath) => {
      if(err) {
        handleError(err);
      } else {
        window.location = window.location.origin + '/backup-file/' + filePath;
      }
    });
  };

  const { domain } = config;

  const styles = {
    badge: {
      marginRight: 8,
      fontSize: 13
    },
    navButton: {
      display: 'block',
      marginTop: '.5em',
      marginBottom: '.5em',
      marginLeft: '.5em',
    },
    brandImage: {
      height: 30,
      paddingTop: 5,
      paddingBottom: 5,
    },
    iconLink: {
      fontSize: 30,
      lineHeight: '30px',
    },
  };

  const onLogsClick = e => {
    e.preventDefault();
    socket.emit(socketEndpoints.GET_LOG_KEY, authKey, (err, key) => {
      if(err) {
        handleError(err);
      } else {
        window.location = window.location.origin + '/log/' + key;
      }
    });
  };

  const onFullShutdownClick = async function(e) {
    e.preventDefault();

    const confirmed = await swal({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This will power down all nodes and shut down Node Pilot. Do you want to continue?',
      dangerMode: true,
      buttons: [
        'Cancel',
        'Shut down Node Pilot'
      ]
    });
    if(!confirmed) return;
    socket.emit(socketEndpoints.FULL_SHUTDOWN, authKey, (err) => {
      if(err) {
        handleError(err);
      } else {
        window.location = window.location.origin + '/shutdown';
      }
    });
  };

  const onShutdownClick = async function(e) {
    e.preventDefault();

    const confirmed = await swal({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This will shut down Node Pilot while leaving all nodes and load balancers running. Do you want to continue?',
      dangerMode: true,
      buttons: [
        'Cancel',
        'Shut down Node Pilot'
      ]
    });
    if(!confirmed) return;
    socket.emit(socketEndpoints.SHUTDOWN, authKey, (err) => {
      if(err) {
        handleError(err);
      } else {
        window.location = window.location.origin + '/shutdown';
      }
    });
  };

  return (
    <div className={'navbar navbar-expand navbar-dark bg-secondary'}>
      <a style={{marginRight: 8}} className="navbar-brand" href={'/'}>
        <img style={styles.brandImage} src={nodePilotWhite} alt={localize.text('Node Pilot Logo', 'navbar')} />
      </a>
      <div className={'d-flex flex-column justify-content-end'} style={{height: 27}}>
        <div style={{fontSize: 12, lineHeight: '12px'}}>{nodePilotVersion ? `v${nodePilotVersion}` : ''}</div>
      </div>

      <div style={{flexGrow: 1}} />
      <span className={'badge badge-outline-success'} style={styles.badge}>{localize.text('Public IP', 'navbar')}: {publicIP}</span>
      {portVerified !== null ? <Link to={routes.PORT_CONFIGURATION} style={styles.badge} className={`badge badge-outline-${portVerified ? 'success' : 'danger'}`}>Public Port: {portVerified ? '443' : 'Not configured'}</Link> : ''}
      {domainVerified !== null ? <Link to={routes.DNS_CONFIGURATION} style={styles.badge} className={`badge badge-outline-${domain && domainVerified ? 'success' : 'danger'}`}>Public Domain: {domain ? <span>{domain} {!domainVerified ? <i className={'mdi mdi-alert-circle'} /> : ''}</span> : 'Not configured'}</Link> : ''}
      {tlsVerified !== null ? <Link to={routes.TLS_CONFIGURATION} style={styles.badge} className={`badge badge-outline-${tlsVerified ? 'success' : 'danger'}`}>TLS Status: {tlsVerified ? 'Verified' : 'Not configured'}</Link> : ''}

      {/*<span className={'badge badge-success'}>Something</span>*/}
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          {/*<li className={'nav-item'}>*/}
          {/*  <div className={'badge badge-success'}>Something</div>*/}
          {/*</li>*/}
          <li className="nav-item mr-2">
            <a className="btn btn-outline-primary" style={styles.navButton} href="#" title={'Download backup file'} onClick={onBackup}>BACKUP</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" style={styles.iconLink} href="#" title={'View logs'} onClick={onLogsClick}><i className={'mdi mdi-file-code-outline'} /></a>
          </li>
          <li className="nav-item">
            <div className="dropdown">
              <a className="nav-link"
                 style={styles.iconLink}
                 href="#" title={'Safe shut down'}
                 id="shutdownDropdownMenuButton"
                 data-toggle="dropdown"><i className={'mdi mdi-power'} /></a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="shutdownDropdownMenuButton">
                <a className="dropdown-item" href="#" onClick={e => onShutdownClick(e)} title={'Shuts down Node Pilot while leaving nodes and load balancers running.'}>Shutdown</a>
                <a className="dropdown-item" href="#" onClick={e => onFullShutdownClick(e)} title={'Shuts down Node Pilot along with all nodes and load balancers.'}>Full Shutdown</a>
              </div>
            </div>
          </li>
          <li className="nav-item mr-1">
            <a className="nav-link" style={styles.iconLink} href="#" title={'Log out'} onClick={onLogOut}><i className={'mdi mdi-account-arrow-right-outline'} /></a>
          </li>
        </ul>
      </div>
    </div>
  );
};
Navbar.propTypes = {
  authKey: PropTypes.string,
  nodePilotVersion: PropTypes.string,
  domainVerified: PropTypes.bool,
  portVerified: PropTypes.bool,
  tlsVerified: PropTypes.bool,
  publicIP: PropTypes.string,
  config: PropTypes.instanceOf(ServerConfig),
  socket: PropTypes.object
};

export default connect(
  ({ appState }) => ({
    authKey: appState.authKey,
    domainVerified: appState.domainVerified,
    portVerified: appState.portVerified,
    tlsVerified: appState.tlsVerified,
    publicIP: appState.publicIP,
    config: appState.config,
    nodePilotVersion: appState.nodePilotVersion,
  })
)(Navbar);
