import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { handleError } from '../../util';
import { routes, socketEndpoints } from '../../constants';
import linuxPortImage1 from '../../images/ubuntu_firewall_01.png';
import Code from "../shared/code";
import moment from 'moment';
import { Link } from 'react-router-dom';
// import macPortImage1 from '../../images/macos_port_01.png';
// import macPortImage2 from '../../images/macos_port_02.png';
// import macPortImage3 from '../../images/macos_port_03.png';

const PortConfiguration = ({ authKey, lastPortCheckTime, portVerified, socket }) => {

  const [ verifyingPort, setVerifyingPort ] = useState(false);

  const onVerifyPortClick = e => {
    e.preventDefault();
    setVerifyingPort(true);
    setTimeout(() => {
      socket.emit(socketEndpoints.VERIFY_PORT, authKey, err => {
        if(err) handleError(err);
        setVerifyingPort(false);
      });
    }, 500);
  };

  const styles = {
    image: {
      display: 'block',
      margin: 'auto',
      maxWidth: '80%',
      height: 'auto',
      marginBottom: 16
    }
  };

  return (
    <div className={'container-fluid'}>
      <div className={'row'}>
        <div className={'col'}>
          <h2><Link to={routes.HOME}><i className={'mdi mdi-arrow-left'} /></Link> Port Configuration</h2>
        </div>
      </div>
      <div className={'row'}>

        <div className={'col-md-6'}>
          <div className={'card'}>
            <h5 className={'card-header'}>Port 443 Status: {portVerified ? <span className={'text-success'}>Verified</span> : <span className={'text-danger'}>Not verified</span>} {lastPortCheckTime ? <em className={'text-muted'}>(last checked {lastPortCheckTime.format('h:mm:ss a')})</em> : ''}</h5>
            <div className={'card-body'}>
              <p className={'card-text'}>
                In order to run a local node, port 443 must be open to the public. If you are on a virtual server like a Digital Ocean droplet, then all that you should need to do is open the port in your operating system. On an Ubuntu server, the most commonly used firewall is UFW. On a desktop computer in a private network, you will need to open the port on your local machine and you will also need to make sure that the same port is mapped from your router to your local machine.
              </p>
              <p className={'card-text'}>
                Once you have allowed port 443 through your firewall and have done any necessary port mapping, click the button below to verify that the port is open the public.
              </p>
              <button type={'button'} disabled={verifyingPort} className={'btn btn-primary'} onClick={onVerifyPortClick}>Verify Port</button>
            </div>
          </div>
        </div>

        <div className={'col-md-6'}>
          <h2>Firewall Guides</h2>

          <h4>Opening ports in Ubuntu</h4>
          <p>The most common firewall for Ubuntu and Ubuntu Server is called ufw. It comes built into Ubuntu, but it isn't enabled by default.</p>
          <p>You will want to open ports 22, 443, 80, and 26656 to the public internet. Port 22 is used for SSH. If that port is not enabled, then you won't be able to SSH into your server. Enable port 22 by entering <Code>sudo ufw allow 22</Code>. Port 443 is the TLS port, which is what Node Pilot will accept connections through. But, in order to generate a TLS cert using Let's Encrypt you need port 80 enabled as well. Enable the TLS port with <Code>sudo ufw allow 443</Code> and port 80 with <Code>sudo ufw allow 80</Code>. You will also need to open the peer port which will be routed to your nodes for peering. The port is 26656 and is routed internally via DNS to the correct node. Open it with <Code>sudo ufw allow 26656</Code>. Finally, there are two more ports which need to be opened on your local system firewall but which you <span className={'text-danger'}>do not</span> want forwarded to the public internet. There are ports 34416 (the port that the admin control parnet runs on) and 19999 (used for the system stats API). Enable these with <Code>sudo ufw allow 34416</Code> and <Code>sudo ufw allow 19999</Code>. After you have enabled the necessary ports, then enable the firewall by entering <Code>sudo ufw enable</Code>. You can see a partial example in the screenshot below.</p>
          <img alt={'Ubuntu firewal'} style={styles.image} src={linuxPortImage1} />

          {/*<h4>Allowing an app through the macOS firewall</h4>*/}
          {/*<p>When you run an application in macOS which attempts to open ports, the operating system asks you if you want to allow incoming connections. When you run Node Pilot, you must select "Allow" to allow port connections through the firewall.</p>*/}
          {/*<img alt={'Mac port dialog'} style={styles.image} src={macPortImage1} />*/}
          {/*<p>If you don't see that dialog when you run the application, you need to check that the firewall is enabled and that the application has not been blocked. First, go to Security & Privacy > Firewall.</p>*/}
          {/*<img alt={'Mac firewall screenshot 1'} style={styles.image} src={macPortImage2} />*/}
          {/*<p>It should say "Firewall: On." If it is off, click the lock to unlock for changes then turn it on. Otherwise, unlock and then select "Firewall Options."</p>*/}
          {/*<img alt={'Mac firewall screenshot 2'} style={styles.image} src={macPortImage3} />*/}
          {/*<p>Make sure that "Block all incoming connections" is NOT selected. If all incoming connections are blocked, then Node Pilot will not work. Scroll down the application list and check to see if np is in there. If you see it in the list and it says "Block incoming directions" you'll need to change it to "Allow incoming connections." If np is not in the list, then click the "+" button, select the np binary, and then allow incoming connections to it. After that, you should be good to go at the operating system level.</p>*/}

        </div>

      </div>
    </div>
  );
};
PortConfiguration.propTypes = {
  authKey: PropTypes.string,
  lastPortCheckTime: PropTypes.instanceOf(moment),
  portVerified: PropTypes.bool,
  history: PropTypes.object,
  socket: PropTypes.object
};

export default PortConfiguration;
