import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { routes } from '../../constants';
import ServerConfig from '../../types/server-config';
import NodeTableHeader from "./node-table-header";
import { Map } from 'immutable';
import $ from 'jquery';
import swal from 'sweetalert';

const PocketNodeTable = ({ chainsMeta, domainVerified, portVerified, tlsVerified, config }) => {

  const onAddClick = e => {
    // e.preventDefault();
    // $('#js-newChainModal').modal('show');
    if(!domainVerified || !portVerified || !tlsVerified) {
      e.preventDefault();
      return swal({
        title: 'Not quite yet!',
        text: 'You must first verify your public port, verify your root domain, and configure TLS. Click the red bubbles at the top of the page to address each.'
      })
    }
  };

  return (
    <div className={'container-fluid'}>
      <div className={'row'}>
        <div className={'col'}>
          <NodeTableHeader text={'VALIDATORS'} to={routes.ADD_NEW_CHAIN + `?ticker=pokt`} onAddClick={onAddClick} />
        </div>
      </div>

      <div className={'row'}>
        <div className={'col'}></div>
      </div>
    </div>
  )
};
PocketNodeTable.propTypes = {
  domainVerified: PropTypes.bool,
  portVerified: PropTypes.bool,
  tlsVerified: PropTypes.bool,
  config: PropTypes.instanceOf(ServerConfig),
  chainsMeta: PropTypes.instanceOf(Map),
};

export default connect(
  ({ appState }) => ({
    portVerified: appState.portVerified,
    domainVerified: appState.domainVerified,
    tlsVerified: appState.tlsVerified,
    config: appState.config,
    chainsMeta: appState.chainsMeta,
  })
)(PocketNodeTable);
