import { connect } from 'react-redux';
import Login from './login';
import { setAuthKey } from '../../actions/app-actions';

export default connect(
  null,
  dispatch => ({
    setAuthKey: authKey => dispatch(setAuthKey(authKey))
  })
)(Login);
