import { connect } from 'react-redux';
import AddNewChain from './add-new-chain';

export default connect(
  ({ appState }) => ({
    authKey: appState.authKey,
    nodeOutput: appState.nodeOutput,
    chains: appState.chains,
  })
)(AddNewChain);
