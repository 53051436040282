import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const OutputModal = ({ command, output, windowHeight }) => {
  return (
    <div className={'modal fade'} id={'js-nodeOutputModal'}>
      <div className={'modal-dialog modal-xl modal-dialog-centered'}>
        <div className={'modal-content'}>
          <div className="modal-header">
            <h4 className="modal-title">Command: <span className={'text-monospace'}>{command}</span></h4>
            <button type="button" className="close" data-dismiss="modal"><span>&times;</span></button>
          </div>
          <div className={'modal-body text-monospace'} style={{maxHeight: windowHeight - 300, overflowX: 'auto', overflowY: 'auto', backgroundColor: '#000', color: '#0f0', fontSize: 14, whiteSpace: 'pre'}}>{output}</div>
          <div className={'modal-footer'}>
            <button className={'btn btn-secondary'} data-dismiss={'modal'}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};
OutputModal.propTypes = {
  command: PropTypes.string,
  output: PropTypes.string,
  windowHeight: PropTypes.number,
};

export default connect(
  ({ appState }) => ({
    windowHeight: appState.windowHeight,
  })
)(OutputModal);
