import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

const NodeTableHeader = ({ text, to, onAddClick = () => {} }) => {

  const styles = {
    addLink: {
      fontSize: '24px',
    },
  };

  return (
    <div className={'mt-3 mb-1 d-flex flex-row justify-content-start align-items-center'}>
      <h4>{text}</h4>
      {to ?
        <Link to={to} style={styles.addLink} className={'text-primary ml-2'} onClick={onAddClick} title={'Add new'}><i className={'mdi mdi-plus-circle-outline'} /></Link>
        :
        <a href={'#'} style={styles.addLink} className={'text-primary ml-2'} onClick={onAddClick} title={'Add new'}><i className={'mdi mdi-plus-circle-outline'} /></a>
      }
    </div>
  );
};
NodeTableHeader.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  onAddClick: PropTypes.func,
};

export default NodeTableHeader;
