import { bindAll, escapeRegExp, isNumber, isString } from 'lodash';

export class Localize {

  _locale = '';
  _localeData = {};
  _collator = new Intl.Collator();

  constructor(locale, localeData) {
    this._locale = locale;
    this._localeData = localeData;
    this._collator = new Intl.Collator(locale);
    bindAll(this, ['text', 'number', 'compare']);
  }

  text(key, context, replacers = {}) {
    const { _localeData: localeData } = this;
    let val = localeData[key] && localeData[key][context] ? localeData[key][context] : key;
    val = Object.keys(replacers).reduce((str, key) => {
      const patt = new RegExp(`{{${escapeRegExp(key)}}}`, 'g');
      return str.replace(patt, replacers[key]);
    }, val);
    return val;
  }

  number(num = 0, options = {useGrouping: false}) {
    return num.toLocaleString(this._locale, options);
  }

  compare(a, b) {
    if(isString(a) && isString(b)) {
      return this._collator.compare(a, b);
    } else if(isNumber(a) && isNumber(b)) {
      return a === b ? 0 : a > b ? 1 : -1;
    } else {
      return 0;
    }
  }

}
