import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { handleError } from '../../util';
import { routes, socketEndpoints } from '../../constants';
import ServerConfig from '../../types/server-config';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import { ServerConfig } from '../../types/server-config';

const DNSConfiguration = ({ authKey, config, domainVerified, lastDomainCheckTime, socket }) => {

  console.log('domain', config.domain);

  const [ newDomain, setNewDomain ] = useState('');
  const [ verifyingDomain, setVerifyingDomain ] = useState(false);
  // const [ lastCheckTime, setLastCheckTime ] = useState(null);

  const domainPatt = /^.+\..+$/;
  const validDomain = domainPatt.test(newDomain.trim());

  useEffect(() => {
    setNewDomain(config.domain);
  }, [config.domain]);

  const onDomainSubmit = e => {
    e.preventDefault();
    const preppedDomain = newDomain
      .trim()
      .replace(/^https?:\/\//i, '');
    if(preppedDomain !== newDomain)
      setNewDomain(preppedDomain);
    socket.emit(socketEndpoints.UPDATE_SERVER_CONFIG, authKey, {domain: newDomain.trim()}, err => {
      if(err) return handleError(err);
      socket.emit(socketEndpoints.VERIFY_DOMAIN, authKey, err1 => {
        if(err1) return handleError(err1);
      });
    });
  };
  const onDomainChange = e => {
    e.preventDefault();
    setNewDomain(e.target.value.toLowerCase());
  };
  const onVerifyDomainClick = e => {
    e.preventDefault();
    setVerifyingDomain(true);
    setTimeout(() => {
      socket.emit(socketEndpoints.VERIFY_DOMAIN, authKey, err1 => {
        if(err1) handleError(err1);
        setVerifyingDomain(false);
      });
    }, 500);
  };

  const styles = {
    verifyCard: {
      position: 'relative',
      // filter: config.domain ? 'none' : 'blur(1px)'
    },
    verifyCardOverlay: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    }
  };

  return (
    <div className={'container-fluid'}>
      <div className={'row'}>
        <div className={'col'}>
          <h2><Link to={routes.HOME}><i className={'mdi mdi-arrow-left'} /></Link> DNS Configuration</h2>
        </div>
      </div>

      <div className={'row'}>
        <div className={'col-md-6'}>

          <form onSubmit={onDomainSubmit} style={{marginBottom: 24}}>
            <div className={'form-group'}>
              <label>Root Domain: <span className={'text-muted'}>(e.g. my-domain.com)</span></label>
              <input type={'text'}
                     className={'form-control'}
                     value={newDomain}
                     onChange={onDomainChange}
                     placeholder={'Enter root domain'} />
            </div>
            <button disabled={newDomain && !validDomain} type={'submit'} className={'btn btn-primary'}>Save root domain</button>
          </form>

          <div className={'card'} style={styles.verifyCard}>
            {/*{config.domain ? null : <div style={styles.verifyCardOverlay} />}*/}
            <h5 className={'card-header'}>Domain Status: {domainVerified ? <span className={'text-success'}>Verified</span> : <span className={'text-danger'}>Not verified</span>} {lastDomainCheckTime && config.domain ? <em className={'text-muted'}>(last checked {lastDomainCheckTime.format('h:mm:ss a')})</em> : ''}</h5>
            <div className={'card-body'}>
              <p className={'card-text'}>
                In order to run a local node, port 443 must be open to the public. If you are on a virtual server like a Digital Ocean droplet, then all that you should need to do is open the port in your operating system. On an Ubuntu server, the most commonly used firewall is UFW. On a desktop computer in a private network, you will need to open the port on your local machine and you will also need to make sure that the same port is mapped from your router to your local machine.
              </p>
              <p className={'card-text'}>
                Once you have allowed port 443 through your firewall and have done any necessary port mapping, click the button below to verify that the port is open the public.
              </p>
              <button type={'button'} disabled={!domainPatt.test(config.domain) || verifyingDomain} className={'btn btn-primary'} onClick={onVerifyDomainClick}>Verify <strong>{config.domain}</strong></button>
          </div>
        </div>

        </div>
      </div>

    </div>
  );
};
DNSConfiguration.propTypes = {
  authKey: PropTypes.string,
  config: PropTypes.instanceOf(ServerConfig),
  domainVerified: PropTypes.bool,
  lastDomainCheckTime: PropTypes.instanceOf(moment),
  history: PropTypes.object,
  socket: PropTypes.object
};

export default DNSConfiguration;
