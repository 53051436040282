import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GaugeChart from 'react-gauge-chart';
import SystemInfo from '../../types/system-info';
import { nodeTypes } from '../../constants';
import NLNode from '../../types/NLNode';

const Gauge = ({ id, topText, bottomText, small = false, percent = 0 }) => {

  const textColor = '#aab8c5';
  const styles = {
    gaugeContainer: {
      height: '100%',
    },
    gauge: {
      width: 300,
    },
    smallGauge: {
      width: 200,
      marginBottom: 6,
    },
    topText: {
      color: textColor,
    },
    bottomText: {
      marginTop: -4,
    },
    bottomBadge: {
      fontSize: 13,
      color: textColor,
      boxShadow: '0px 0px 3px 0px #f1f1f1',
    },
  };

  return (
    <div style={styles.gaugeContainer} className={'d-flex flex-column justify-content-end align-items-center'}>
      <h4 style={styles.topText}>{topText}</h4>
      <GaugeChart
        style={small ? styles.smallGauge : styles.gauge}
        colors={['#0acf97', '#727cf5', '#fa5c7c']}
        arcPadding={small ? 0.06 : 0.04}
        cornerRadius={0}
        arcWidth={small ? 0.05 : 0.0333}
        nrOfLevels={3}
        arcsLength={[50, 30, 20]}
        percent={Number((percent || 0).toFixed(0)) / 100}
        id={id} />
      <div style={styles.bottomText}>
        <div style={styles.bottomBadge} className={'badge badge-outline-light'}>{bottomText}</div></div>
    </div>
  );
};
Gauge.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  small: PropTypes.bool,
  percent: PropTypes.number,
};

const SystemStats = ({ chains, systemInfo }) => {

  const cpusUsed = chains
    .filter(n => n.type !== nodeTypes.EXTERNAL && !n.remote)
    .reduce((num, { dockerCPUs }) => {
      return num += dockerCPUs;
    }, 0);

  const styles = {
    smallerGaugeChart: {
    },
    gaugeChart: {
      width: 300,
    },
    container: {
      paddingTop: 50,
    },
  };

  return (
    <div style={styles.container} className={'container-fluid'}>
      <div className={'row'}>
        <div className={'col'} />
        <div className={'col'}>
          <Gauge
            id={'js-memGuage'}
            topText={'MEMORY'}
            bottomText={`${(systemInfo.memUsed || 0).toFixed(1)} / ${(systemInfo.memTotal || 0).toFixed(1)} ${systemInfo.memUnits}`}
            percent={systemInfo.memPercent}
            small={true} />
        </div>
        <div className={'col'}>
          <Gauge
            id={'js-cpuGuage'}
            topText={'vCPU'}
            bottomText={`${cpusUsed} / ${systemInfo.cpuTotal} vCPUs Assigned`}
            percent={systemInfo.cpuPercent} />
        </div>
        <div className={'col'}>
          <Gauge
            id={'js-diskGuage'}
            topText={'DISK'}
            bottomText={`${(systemInfo.diskUsed || 0).toFixed(1)} / ${(systemInfo.diskTotal || 0).toFixed(1)} ${systemInfo.diskUnits}`}
            percent={systemInfo.diskPercent}
            small={true} />
        </div>
        <div className={'col'} />
      </div>
    </div>
  );
};
SystemStats.propTypes = {
  authKey: PropTypes.string,
  socket: PropTypes.object,
  chains: PropTypes.arrayOf(PropTypes.instanceOf(NLNode)),
  systemInfo: PropTypes.instanceOf(SystemInfo),
};

export default connect(
  ({ appState }) => ({
    authKey: appState.authKey,
    chains: appState.chains,
    systemInfo: appState.systemInfo,
  })
)(SystemStats);
