import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { socketEndpoints, routes } from '../../constants';
import { handleError } from '../../util';
import nodePilotImage from '../../images/node_pilot_white.png';

const Register = ({ history, socket, setAuthKey }) => {

  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ passwordRepeat, setPasswordRepeat ] = useState('');

  const styles = {
    container: {
      position: 'absolute',
      left: 10,
      right: 10,
      top: 0,
      bottom: 0
    },
    innerContainer: {
      width: 500,
      maxWidth: '100%'
    }
  };

  const onSubmit = async function(e) {
    e.preventDefault();
    const preppedName = name.trim();
    const emailPatt = /^[^@]+@[^@]+.\.[^@]+$/;
    const preppedEmail = email.trim();
    if(!preppedName) {
      await swal('Oops!', 'You must enter a name.', 'error');
    } else if(!preppedEmail || !emailPatt.test(preppedEmail)) {
      await swal('Oops!', 'You must enter a valid email address.', 'error');
    } else if(!password) {
      await swal('Oops!', 'You must enter a password.', 'error');
    } else if(password.length < 12) {
      await swal('Oops!', 'Your password must be at least twelve characters long.', 'error');
    } else if(!passwordRepeat) {
      await swal('Oops!', 'You must repeat your password.', 'error');
    } else if(password && passwordRepeat && password !== passwordRepeat) {
      await swal('Oops!', 'Your password and repeated password must match.', 'error');
    } else { // name, email, password, and password repeat are good
      socket.emit(socketEndpoints.REGISTER, { name, email, password }, err => {
        if(err) {
          handleError(err);
        } else {
          socket.emit(socketEndpoints.AUTHENTICATE, { email, password }, (err, authKey) => {
            if(err) {
              console.log(err);
              handleError(err);
              setAuthKey('');
            } else {
              setAuthKey(authKey);
              history.push(routes.HOME);
            }
          });
        }
      });
    }
  };

  return (
    <div style={styles.container} className={'d-flex flex-row justify-content-center align-items-center'}>
      <div style={styles.innerContainer}>
        <div className={'d-flex flex-row justify-content-center mb-3'}>
          <img style={{width: 500, maxWidth: '90%'}} src={nodePilotImage} alt={'Node Pilot'} />
        </div>
        {/*<h1 className={'text-center'}>Node Pilot</h1>*/}
        {/*<h3 className={'text-center'}>User Registration</h3>*/}
        <p>This appears to be your first time running Node Pilot! First off, you need to set up a user. Your name and email are only used locally. They are not sent to Decentralized Authority and will not leave your machine without your explicit permission. This email will be used to log into the system. Be very careful to save this login information because there is no way to recover it if you lose it and you will therefore no longer be able to log into this instance of Node Pilot.</p>
        <form onSubmit={onSubmit}>
          <div className={'form-group'}>
            <label>Name:</label>
            <input className={'form-control'} type={'text'} value={name} onChange={e => setName(e.target.value)} autoFocus required />
          </div>
          <div className={'form-group'}>
            <label>Email:</label>
            <input className={'form-control'} type={'email'} value={email} onChange={e => setEmail(e.target.value.toLowerCase())} required />
          </div>
          <div className={'form-group'}>
            <label>Password: <em className={'text-muted'}>(password must be at least twelve characters long)</em></label>
            <input className={'form-control'} type={'password'} value={password} onChange={e => setPassword(e.target.value)} required />
          </div>
          <div className={'form-group'}>
            <label>Repeat Password:</label>
            <input className={'form-control'} type={'password'} value={passwordRepeat} onChange={e => setPasswordRepeat(e.target.value)} required />
          </div>
          <div className={'form-group'}>
            <button type={'submit'} className={'btn btn-outline-primary btn-lg w-100'}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};
Register.propTypes = {
  history: PropTypes.object,
  socket: PropTypes.object,
  setAuthKey: PropTypes.func
};

export default withRouter(Register);
