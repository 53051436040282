import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routes, socketEndpoints } from '../../constants';
import moment from 'moment';
import { handleError } from '../../util';
import swal from 'sweetalert';
import ServerConfig from "../../types/server-config";
import { LocalizeContext } from "../../hooks/localize-hook";
import isBoolean from 'lodash/isBoolean';

const TLSConfiguration = ({ authKey, config, tlsVerified, lastTLSCheckTime, portVerified, domainVerified, socket }) => {

  const localize = useContext(LocalizeContext);

  const [ tlsCertExists, setTLSCertExists ] = useState(null);
  const [ renewingTLS, setRenewingTLS ] = useState(false);
  const [ verifyingTLS, setVerifyingTLS ] = useState(false);
  const [ letsencryptOutput, setLetsencryptOutput ] = useState('');

  let outputNode;

  useEffect(() => {
    socket.on(socketEndpoints.LETSENCRYPT_OUTPUT, str => {
      setLetsencryptOutput(letsencryptOutput + str);
      if(outputNode) outputNode.scrollTop = outputNode.scrollHeight;
    });
    return () => {
      socket.removeAllListeners(socketEndpoints.LETSENCRYPT_OUTPUT);
    };
  });

  useEffect(() => {
    let cleanedUp = false;
    if(authKey && socket) {
      try {
        socket.emit(socketEndpoints.TLS_CERT_EXISTS, authKey, async function(err, certsExist){
          if(cleanedUp) return;
          if(err) {
            setTLSCertExists(false);
            handleError(err);
          } else {
            setTLSCertExists(certsExist);
          }
        });
      } catch(err) {
        setTLSCertExists(false);
        handleError(err);
      }
    }
    return () => {
      cleanedUp = true;
    };
  }, [authKey, socket]);

  const onRenewTLSClick = e => {
    e.preventDefault();
    setRenewingTLS(true);
    socket.emit(socketEndpoints.RENEW_TLS_CERT, authKey, async function(err, success) {
      setRenewingTLS(false);
      if(err) {
        handleError(err);
      } else if(success) {
        setTimeout(() => {
          swal({
            title: 'Success!',
            text: 'The TLS certs have successfully been renewed.',
            icon: 'success',
            closeOnClickOutside: false,
            closeOnEsc: false,
            button: false
          }).then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
        }, 1000);
      } else {
        setTimeout(() => {
          swal({
            title: 'Certs Not Updated',
            text: 'TLS certs were not updated. Check the output for more information.',
            icon: 'warning',
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }, 1000);
      }
    });
  };

  const onConfigureTLSClick = e => {
    e.preventDefault();
    setVerifyingTLS(true);
    socket.emit(socketEndpoints.CONFIGURE_TLS, authKey, async function(err, success) {
      if(err) {
        setVerifyingTLS(false);
        handleError(err);
      } else if(success) {
        setTimeout(() => {
          swal({
            title: 'Success!',
            text: 'The TLS certs have successfully been created. The server is restarting and this page will automatically reload in a few seconds.',
            closeOnClickOutside: false,
            closeOnEsc: false,
            button: false
          });
          socket.emit(socketEndpoints.RESTART_SERVER, authKey, err1 => {
            if(err1) {
              handleError(err1);
            } else {
              setTimeout(() => {
                window.location = `https://${config.domain}`;
              }, 2000);
            }
          });
        }, 1000);
      }
    });
  };

  const styles = {
    listItem: {
      listStyleType: 'none'
    }
  };

  return (
    <div className={'container-fluid'}>
      <div className={'row'}>
        <div className={'col'}>
          <h2><Link to={routes.HOME}><i className={'mdi mdi-arrow-left'} /></Link> TLS Configuration</h2>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-md-6'}>
          <div className={'card'}>
            <h5 className={'card-header'}>TLS Status: {tlsVerified ? <span className={'text-success'}>Configured</span> : <span className={'text-danger'}>Not configured</span>} {lastTLSCheckTime ? <em className={'text-muted'}>({localize.text('last checked {{time}}', 'tlsConfiguration', {time: lastTLSCheckTime.format('h:mm:ss a')})})</em> : ''}</h5>
            <div className={'card-body'}>
              <p className={'card-text'}>
                Initially, Node Pilot server runs using a self-signed certificate. It is necessary to request a TLS certificate from a verified CA (certificate authority) in order for clients who connect to your nodes to be able to trust who you are. Node Pilot automates this process by using Let's Encrypt.
              </p>
              <p className={'card-text'}>
                If you have successfully opened the required ports and have properly configured your domain, then you are ready to configure TLS using Let's Encrypt. Click below to request your TLS certificate and automatically configure your server to use it.
              </p>
              <ul>
                <li style={styles.listItem}>{portVerified ? <i className={'mdi mdi-check-circle text-success'} /> : <i className={'mdi mdi-close-circle text-danger'} />} Port Verified</li>
                <li style={styles.listItem}>{domainVerified ? <i className={'mdi mdi-check-circle text-success'} /> : <i className={'mdi mdi-close-circle text-danger'} />} Domain Verified</li>
              </ul>
              {isBoolean(tlsCertExists) ?
                <button type={'button'} disabled={!portVerified || !domainVerified || verifyingTLS || renewingTLS} className={'btn btn-primary'} onClick={!tlsCertExists ? onConfigureTLSClick : onRenewTLSClick}>{!tlsCertExists ? localize.text('Automatically Configure TLS') : localize.text('Manually Renew TLS Certs')}</button>
                :
                null
              }
            </div>
          </div>
        </div>
        <div className={'col-md-6'}>
          <h2>Let's Encrypt Output</h2>
          <div ref={node => node ? outputNode = node : null} style={{backgroundColor: '#000', color: '#0f0', paddingLeft: 8, height: 500, whiteSpace: 'pre-line', overflowY: 'auto'}} className={'text-monospace'}>{letsencryptOutput}</div>
        </div>
      </div>
    </div>
  );
};
TLSConfiguration.propTypes = {
  authKey: PropTypes.string,
  config: PropTypes.instanceOf(ServerConfig),
  tlsVerified: PropTypes.bool,
  portVerified: PropTypes.bool,
  domainVerified: PropTypes.bool,
  lastTLSCheckTime: PropTypes.instanceOf(moment),
  history: PropTypes.object,
  socket: PropTypes.object
};

export default TLSConfiguration;
