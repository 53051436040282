import React from 'react';
import PropTypes from 'prop-types';
import PocketNodeTable from '../shared/pocket-node-table';
import ChainsTable from '../shared/chains-table';
import SystemStats from '../shared/system-stats';
import NLNode from '../../types/NLNode';
import { Role } from '../../constants';

const Home = ({ chains, history, socket }) => {

  const styles = {
    container: {
      // position: 'absolute',
      // left: 0,
      // right: 0,
      // top: 0,
      // bottom: 0
    }
  };

  return (
    <div style={styles.container}>
      <SystemStats socket={socket} />
      <PocketNodeTable />
      <ChainsTable noHeader={true} chains={chains.filter(c => c.role === Role.VALIDATOR)} history={history} socket={socket} />
      <ChainsTable chains={chains.filter(c => c.role !== Role.VALIDATOR)} history={history} socket={socket} />
    </div>
  );
};
Home.propTypes = {
  history: PropTypes.object,
  socket: PropTypes.object,
  chains: PropTypes.arrayOf(PropTypes.instanceOf(NLNode)),
};

export default Home;
