import { connect } from 'react-redux';
import DNSConfiguration from './dns-configuration';

export default connect(
  ({ appState }) => ({
    authKey: appState.authKey,
    config: appState.config,
    domainVerified: appState.domainVerified,
    lastDomainCheckTime: appState.lastDomainCheckTime
  })
)(DNSConfiguration);
