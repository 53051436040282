import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PocketChainDataItem } from '../../types/pocket-chain-data-item';
import NLNode from '../../types/NLNode';
import { connect } from 'react-redux';
import { chains as chainsObj } from '../../constants';
import { networkTypes } from '../../constants';

const mainnetRelayChainIds = {
  pokt: {
    [networkTypes.MAINNET]: {
      [false]: '0001',
    },
  },
  [chainsObj.AVAX]: {
    [networkTypes.MAINNET]: {
      [false]: '0003',
      [true]: '00A3',
    },
  },
  [chainsObj.BSC]: {
    [networkTypes.MAINNET]: {
      [false]: '0004',
      [true]: '0010',
    },
    [networkTypes.TESTNET]: {
      [false]: '0011',
      [true]: '0012',
    },
  },
  [chainsObj.BTC]: {
    [networkTypes.MAINNET]: {
      [false]: '0002',
    },
  },
  [chainsObj.ETH]: {
    [networkTypes.MAINNET]: {
      [false]: '0021',
      [true]: '0022',
    },
    [networkTypes.GOERLI]: {
      [false]: '0026',
    },
  },
  [chainsObj.FUSE]: {
    [networkTypes.MAINNET]: {
      [false]: '0005',
      [true]: '000A',
    },
  },
  [chainsObj.ONE]: {
    [networkTypes.MAINNET]: {
      [false]: '0040',
      [true]: '0A40',
    },
  },
  [chainsObj.XDAI]: {
    [networkTypes.MAINNET]: {
      [false]: '0027',
      [true]: '000C',
    },
  },
  [chainsObj.OEC]: {
    [networkTypes.MAINNET]: {
      [false]: '0047',
    },
  },
  [chainsObj.IOTEX]: {
    [networkTypes.MAINNET]: {
      [false]: '0044',
    },
  },
  [chainsObj.MATIC]: {
    [networkTypes.MAINNET]: {
      [false]: '0009',
      [true]: '000B',
    },
  },
};

const getRelayChainId = (network, chain) => {
  return mainnetRelayChainIds[chain.ticker] && mainnetRelayChainIds[chain.ticker][chain.network] && mainnetRelayChainIds[chain.ticker][chain.network][chain.archival || false] ? mainnetRelayChainIds[chain.ticker][chain.network] && mainnetRelayChainIds[chain.ticker][chain.network][chain.archival || false] : '';
};

const AddPocketRelayChain = ({ selectedNode, chainData, chains, onAddChain = () => {}, onCancel = () => {} }) => {

  const [ selectedChain, setSelectedChain ] = useState('');

  const onSelectedChainChange = e => {
    e.preventDefault();
    setSelectedChain(e.target.value);
  };

  const { network } = selectedNode;

  let filteredChains = [];
  const used = [];
  for(const c of chains) {
    const id = getRelayChainId(network, c);
    if(
      id
      && !chainData.some(cd => cd.id === id)
      && !used.includes(id)
    ) {
      used.push(id);
      filteredChains.push(c);
    }
  }

  if(selectedNode.network === 'MAINNET' && !chainData.find(cd => cd.id === '0001')) {
    filteredChains = [
      selectedNode,
      ...filteredChains,
    ];
  }

  const onAddClick = e => {
    e.preventDefault();
    const chain = chains.find(c => getRelayChainId(network, c) === selectedChain) || selectedNode;
    onAddChain(selectedChain, chain);
  };
  const onCancelClick = e => {
    e.preventDefault();
    onCancel();
  };

  return (
    <div>
      <h3>Add Pocket Relay Chain</h3>
      <div className={'form-group'}>
        <select className={'form-control'} value={selectedChain} onChange={onSelectedChainChange} disabled={filteredChains.length === 0}>
          <option value={''}>{filteredChains.length === 0 ? `No additional relay chains available for Pocket ${network}` : 'Select One'}</option>
          {filteredChains
            .map(c => {
              const id = getRelayChainId(network, c);
              return (
                <option key={id} value={id}>{`${id} ${c.remote ? `${c.remoteProtocol}://${c.remoteDomain}:${c.rpcPort}` : `${c.ticker.toUpperCase()} ${c.network}${c.archival ? ' ARCHIVAL' : ''}`}`}</option>
              );
            })
          }
        </select>
      </div>
      <div className={'d-flex flex-row justify-content-start'}>
        <button type={'button'} className={'btn btn-primary'} disabled={!selectedChain} onClick={onAddClick}>Add Chain</button>
        <button type={'button'} className={'ml-2 btn btn-secondary'} onClick={onCancelClick}>Cancel</button>
      </div>
    </div>
  );
};
AddPocketRelayChain.propTypes = {
  chainData: PropTypes.arrayOf(PropTypes.instanceOf(PocketChainDataItem)),
  chains: PropTypes.arrayOf(PropTypes.instanceOf(NLNode)),
  selectedNode: PropTypes.instanceOf(NLNode),
  onAddChain: PropTypes.func,
  onCancel: PropTypes.func,
};

export default connect(
  ({ appState }) => ({
    chains: [...appState.chains.values()],
  }),
)(AddPocketRelayChain);
