import { connect } from 'react-redux';
import PortConfiguration from './port-configuration';

export default connect(
  ({ appState }) => ({
    authKey: appState.authKey,
    portVerified: appState.portVerified,
    lastPortCheckTime: appState.lastPortCheckTime
  })
)(PortConfiguration);
