import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { socketEndpoints } from '../../constants';
import { handleError } from "../../util";
import swal from 'sweetalert';

const ChainDirSelect = ({ authKey, defaultValue = '', value = '', socket, onChange }) => {

  const [ selectedDir, setSelectedDir ] = useState('');
  const [ files, setFiles ] = useState([]);
  useEffect(() => {
    setSelectedDir(value);
  },  [socket, value]);

  useEffect(() => {
    socket.emit(socketEndpoints.READDIR, authKey, selectedDir, (err, res) => {
      if(err) {
        handleError(err);
      } else {
        setFiles(res);
      }
    });
  }, [selectedDir, socket]);

  const onClickInput = async function(e) {
    e.preventDefault();
    if(!selectedDir || selectedDir === defaultValue) {
      const confirmed = await swal({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'Are you sure that you want to change the directory where this chain\'s data will be stored? This location will be hard-coded into the node\'s data and will potentially make your setup less portable. If you ever backup your Node Pilot data and want to move your nodes to a new machine, you will need to make sure that the same directory structures exist.',
        buttons: [
          'Cancel',
          'OK',
        ]
      });
      if(!confirmed) return;
    }
    $('#js-chainDirModal').modal({
      backdrop: 'static',
      keyboard: false,
    });
  };

  const onCancelClick = e => {
    e.preventDefault();
    $('#js-chainDirModal').modal('hide');
    setSelectedDir(value);
  };

  const onSaveClick = e => {
    e.preventDefault();
    $('#js-chainDirModal').modal('hide');
    onChange(selectedDir);
  };

  const onUpClick = e => {
    e.preventDefault();
    const split = selectedDir
      .split('/');
    let newSelectedDir = split
      .slice(0, split.length - 1)
      .join('/');
    setSelectedDir(newSelectedDir || '/');
  };

  const onFileClick = (e, file) => {
    e.preventDefault();
    setSelectedDir(selectedDir + (selectedDir === '/' ? '' : '/') + file);
  };

  const onRestoreDefault = e => {
    e.preventDefault();
    setSelectedDir(defaultValue);
    onChange(defaultValue);
  };

  return (
    <div className={'form-group'}>
      <label>Chains Directory: <a href={'#'} onClick={onRestoreDefault}><em>(Restore Default)</em></a></label>
      <input type={'text'} style={{cursor: 'pointer'}} className={'form-control text-monospace'} onClick={onClickInput} value={value} readOnly={true} />

      <div id={'js-chainDirModal'} className="modal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Browse for chains directory</h5>
            </div>
            <div className="modal-body">
              <div className={'row'}>
                <button type={'button'} className={'btn btn-info btn-sm'} disabled={selectedDir === '/'} onClick={onUpClick}><strong>{'↰'}</strong></button>
                <h5 style={{marginLeft: '8px'}}>{selectedDir}</h5>
              </div>
              <div style={{fontSize: 15, lineHeight: '15px', minHeight: 300, maxHeight: 300, height: 300, overflowY: 'auto'}} className={'text-monospace'}>
                <div>{'│'}</div>
                {files
                  .map((f, idx) => {
                    const treeChars = idx === files.length - 1 ? '└─' : '├─';
                    return (
                      <div key={f}>{treeChars} <i className={'mdi mdi-folder-outline'} /> <a href={'#'} className={'text-info'} onClick={e => onFileClick(e, f)}>{f}</a></div>
                    );
                  })
                }
                {files.length === 0 ? <div>{'└─ Empty directory'}</div> : null}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onCancelClick}>Cancel</button>
              <button type="button" className="btn btn-primary" onClick={onSaveClick}>Save</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
ChainDirSelect.propTypes = {
  authKey: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  socket: PropTypes.object,
  onChange: PropTypes.func
};

export default ChainDirSelect;
