import omit from 'lodash/omit';

class ServerConfig {

  constructor(data = {}) {
    if(data.createdAt) this.createdAt = data.createdAt;
    if(data.updatedAt) this.updatedAt = data.updatedAt;
    this.domain = data.domain || '';
    this.private = data.private || false;
    this.ipType = data.ipType || '';
    this.port = data.port || 34416;
  }

  set(data) {
    return new ServerConfig({
      ...this,
      ...data
    });
  }

  delete(key) {
    return new ServerConfig(omit(this, [key]));
  }

}

export default ServerConfig;
